import {addLeadingZero} from '../helpers/quiz-helper'

const CountDownTpl = ({hours, minutes, seconds, completed}) => {
  if (completed) {
    return <></>
  } else {
    minutes += hours * 60

    return (
      <>
        {!isNaN(minutes) && (
          <>
            <div className='d-flex align-items-baseline text-danger'>
              <div className='fs-1 fw-bolder'>{addLeadingZero(minutes)}</div>
              <div className='fs-8'>M</div>
            </div>
            <span className='ms-2'>:</span>
            <div className='d-flex align-items-baseline text-danger'>
              <div className='fs-1 fw-bolder ms-2'>{addLeadingZero(seconds)}</div>
              <div className='fs-8'>S</div>
            </div>
          </>
        )}
      </>
    )
  }
}

export default CountDownTpl
