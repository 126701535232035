import {KTIcon} from '../../../../_metronic/helpers'
import {ImageViewer} from '../../../components/ImagePicker'

const AttendanceStudentSuccess = ({message, picture = ''}) => {
  return (
    <div className='d-flex flex-center flex-column py-10'>
      <div className='mb-4 w-250px mh-250px'>
        <ImageViewer initialValue={picture} imageClassName='w-250px h-250px mh-250px' />
      </div>
      <div className='fs-3 fw-bold text-gray-700'>{message}</div>
    </div>
  )
}

export default AttendanceStudentSuccess
