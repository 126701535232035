import {KTIcon} from '../../../../_metronic/helpers'

const SurveyAnswerError = ({message}) => {
  return (
    <div className='d-flex flex-center flex-column py-10 h-100' style={{flexGrow: 1}}>
      <div className='mb-4'>
        <KTIcon className='fs-6rem text-danger' iconName='cross-square' iconType='duotone' />
      </div>
      <div className='fs-3 fw-bold text-danger'>{message}</div>
    </div>
  )
}

export default SurveyAnswerError
