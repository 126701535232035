import {ITimestamp} from './ITimestamp'
import {IClassQuizAnswer} from './IClassQuizAnswer'

export interface IClassQuiz extends ITimestamp {
  id: number
  name: string
  duration: number
  description: string
  questionCount: number
  answerId?: any
  answerStatusId?: any
  answerDate?: Date
  answerScore?: any
  answerStudentNim?: any
  answerStudentName?: any
  classId: number
  courseQuizId: number
  startDate: Date
  endDate: Date
  answers: IClassQuizAnswer[]
  statusId?: number
  isCanStart: boolean
  isExam: boolean
  submitDate?: Date
  isUsingSeb: boolean
  lastFraudStartDate?: string
}

export interface Question {
  id: number
  quizId: number
  dataStatusId: number
  typeName: string
  typeId: number
  seq: number
  questionText: string
  option1?: string
  option2?: string
  option3?: string
  option4?: string
  option5?: string
  option6?: string
  correctAnswer?: number
  correctAnswerNumber?: any
  correctAnswerEssay?: any
  correctScore: number
}

export enum QuizAnswerStatusEnum {
  Draft = 5,
  Processed = 10,
  NeedCalculate = 20,
  Completed = 30,
}

export interface IClassQuizLive {
  remainingSeconds: number
  isPrevButtonDisabled: boolean
  useTimerPerQuestion: boolean
}
