import * as signalR from "@microsoft/signalr";

const URL = process.env.REACT_APP_HUB_URL ?? "https://localhost:7285/hub"; // or whatever your backend port is
const REACT_APP_HUB = process.env.REACT_APP_HUB == 'true';
class Connector {
  private topics: string[] = [];
  private connection: signalR.HubConnection | undefined;
  private eventHandlers: { [topic: string]: boolean } = {};
  private connectCount = 0;
  private maxConnectCount = 3;

  public events!: (topic: string, onMessageReceived: (username: string, message: string) => void) => void;

  static instance: Connector;

  constructor() {
    if(REACT_APP_HUB){
      this.connection = new signalR.HubConnectionBuilder().withUrl(URL).withAutomaticReconnect().build();
      this.connect();
    }
    this.setupEventHandlers();
  }

  private connect() {
    this.connectCount++;
    if (this.connectCount <= this.maxConnectCount) {
      this.connection?.start().catch((err) => {
        console.log("Cannot connect")
        if (this.connectCount !== this.maxConnectCount) {
          setTimeout(() => {
            console.log("Try to reconnect");
            this.connect();
          }, 10000)
        }
      })
    }
  }

  private setupEventHandlers() {
        this.events = (topic, onMessageReceived) => {
          if(REACT_APP_HUB) {
            if (!this.eventHandlers[topic]) {
              this.topics.push(topic);
              this.connection?.on(topic, (username, message) => {
                onMessageReceived(username, message);
              });

              this.eventHandlers[topic] = true;
            }
          }
      };
  }

  public newMessage = (topic: string, messages: string) => {
    this.connection?.send("newMessage", topic, messages).then((x) => console.log("sent"));
  };

  public unsubscribe = (topic: string) => {
    if(REACT_APP_HUB){
        if (this.connection && this.connection.off) {
        this.connection.off(topic); // Remove the event handler for the specified topic
        this.eventHandlers[topic] = false;
        this.topics.splice(this.topics.indexOf(topic), 1);
      }
    }
  };

  public unsubscribeAll = () => {
    if (this.connection && REACT_APP_HUB) {
      this.topics.forEach((topic: string) => {
        this.connection?.off(topic);
        this.eventHandlers[topic] = false;
      })
      this.topics = []
    }
  }

  public static getInstance(): Connector {
    if (!Connector.instance) Connector.instance = new Connector();
    return Connector.instance;
  }
}

export default Connector.getInstance;
