/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {getUserByToken, register} from '../core/_requests'
import {Link, Outlet} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import LoadingButtonWrapper from '../../../components/LoadingButtonWrapper'
import PreventAutoComplete from '../../../components/PreventAutoComplete'
import {InputGroup} from 'react-bootstrap'
import SatkerSelect from './SatkerSelect'
import OfficerRankSelect from './OfficerRankSelect'
import {RegistrationForm} from './RegistrationForm'

export function Registration() {

  return (
    <div
      className='d-flex flex-column flex-column-fluid flex-lg-row'
      style={{
        // background:
        // 'radial-gradient(circle at 10% 20%, rgb(226, 37, 37) 0%, rgb(211, 49, 49) 82.8%)',
        backgroundImage: `url('${toAbsoluteUrl('/media/bg-7.webp')}')`,
        // backgroundSize: '100%',
        backgroundPosition: 'bottom right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundColor: '#390d0a',
        alignItems: 'center',
      }}
    >
      <div
        className='d-flex flex-column flex-column-fluid flex-lg-row h-100 w-100 justify-content-center'
        // style={{backdropFilter: 'blur(0px)', backgroundColor: 'rgba(0,0,0,0.7)'}}
      >
        <div className='d-flex align-items-center p-8 p-md-20 justify-content-center'>
          <div
            className='bg-body d-flex flex-column flex-md-row align-items-stretch flex-center rounded-4 w-800px overflow-hidden'>
            <div className="w-100">
              <div className='text-center mb-11 w-100 py-8' style={{
                backgroundColor: 'rgb(156 29 26)',
              }}>
                <img className='mb-3' alt='Logo' src='/media/logos/logo-dark.webp' style={{width: 65}} />
                {/* begin::Title */}
                <h1 className='text-white fw-bolder mb-3'>Sign Up</h1>
                {/* end::Title */}
              </div>
              {/* end::Heading */}
            <div
              className='d-flex flex-center flex-column flex-column-fluid pb-14 pb-md-20 px-14 px-md-20 pt-6 pt-md-12'
            >
              <RegistrationForm />
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
