/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid flex-lg-row'
      style={{
        // background:
        // 'radial-gradient(circle at 10% 20%, rgb(226, 37, 37) 0%, rgb(211, 49, 49) 82.8%)',
        backgroundImage: `url('${toAbsoluteUrl('/media/bg-7.webp')}')`,
        // backgroundSize: '100%',
        backgroundPosition: 'bottom right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundColor: '#390d0a',
        alignItems: 'center',
      }}
    >
      <div
        className='d-flex flex-column flex-column-fluid flex-lg-row h-100 w-100 justify-content-center'
        // style={{backdropFilter: 'blur(0px)', backgroundColor: 'rgba(0,0,0,0.7)'}}
      >
        <div className='d-flex align-items-center p-8 p-md-20 justify-content-center'>
          <div className='bg-body d-flex flex-column flex-md-row align-items-stretch flex-center rounded-4 w-800px overflow-hidden'>
            <div
              className='flex-center'
              style={{
                display: 'flex',
                padding: '24px 32px',

                //   backgroundImage: `url('${toAbsoluteUrl('/media/auth-bg.jpg')}')`,
                //   backgroundRepeat: 'no-repeat',
                //   backgroundSize: 'cover',
                backgroundColor: 'rgb(156 29 26)',
                alignItems: 'center',
              }}
            >
              <div
                className='d-flex flex-column flex-center'
                style={{
                  // backgroundColor: 'rgb(255 255 255 / 13%)',
                  borderRadius: 12,
                  height: '100%',
                }}
              >
                <img
                  className='mb-3'
                  alt='Logo'
                  src='/media/logos/logo-dark.webp'
                  style={{width: 65}}
                />
                <div className='text-center ms-5'>
                  <h1 className='text-white fw-bold m-0' style={{fontSize: '2.4rem'}}>
                    STIK - PTIK
                  </h1>
                  <h5 className='text-white fw-normal m-0'>Learning Management System</h5>
                </div>
              </div>
            </div>
            <div
              className='d-flex flex-center flex-column flex-column-fluid p-14 p-md-20'
              // style={{
              //   backgroundImage: `url('${toAbsoluteUrl('/media/auth-bg.jpg')}')`,
              //   backgroundRepeat: 'no-repeat',
              //   backgroundSize: 'cover',
              //   alignItems: 'center',
              // }}
            >
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {AuthLayout}
