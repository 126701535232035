/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, createContext, useContext, useEffect, useState} from 'react'
import {WithChildren} from '../../helpers'

export interface PageLink {
  title: string
  path: string
  isActive: boolean
  isSeparator?: boolean
}

export interface PageDataContextModel {
  pageTitle?: string
  setPageTitle: (_title: string) => void
  pageDescription?: string
  setPageDescription: (_description: string) => void
  pageBreadcrumbs?: Array<PageLink>
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void
  showBackButton?: boolean
  setShowBackButton: (_showBackButton: boolean) => void,
  actionButton?: any,
  setActionButton: (_actionButton: any) => void,
}

const PageDataContext = createContext<PageDataContextModel>({
  setPageTitle: (_title: string) => {},
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => {},
  setPageDescription: (_description: string) => {},
  setShowBackButton: (_showBackButton: boolean) => {},
  setActionButton: (_actionButtonIcon: any) => {},
})

const PageDataProvider: FC<WithChildren> = ({children}) => {
  const [pageTitle, setPageTitle] = useState<string>('')
  const [pageDescription, setPageDescription] = useState<string>('')
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([])
  const [showBackButton, setShowBackButton] = useState<boolean>(false)
  const [actionButton, setActionButton] = useState<any>()
  const value: PageDataContextModel = {
    pageTitle,
    setPageTitle,
    pageDescription,
    setPageDescription,
    pageBreadcrumbs,
    setPageBreadcrumbs,
    showBackButton,
    setShowBackButton,
    actionButton,
    setActionButton
  }

  return <PageDataContext.Provider value={value}>{children}</PageDataContext.Provider>
}

function usePageData() {
  return useContext(PageDataContext)
}

type Props = {
  description?: string
  breadcrumbs?: Array<PageLink>
  showBackButton?: boolean
  actionButton?: any
}

const PageTitle: FC<Props & WithChildren> = ({
  children,
  description,
  breadcrumbs,
  showBackButton,
  actionButton,
}) => {
  const {setPageTitle, setPageDescription, setPageBreadcrumbs, setShowBackButton, setActionButton} = usePageData()
  useEffect(() => {
    if (children) {
      setPageTitle(children.toString())
    }
    return () => {
      setPageTitle('')
    }
  }, [children])

  useEffect(() => {
    if (description) {
      setPageDescription(description)
    }
    return () => {
      setPageDescription('')
    }
  }, [description])

  useEffect(() => {
    if (breadcrumbs) {
      setPageBreadcrumbs(breadcrumbs)
    }
    return () => {
      setPageBreadcrumbs([])
    }
  }, [breadcrumbs])

  useEffect(() => {
    if (showBackButton) {
      setShowBackButton(showBackButton)
    }
    return () => {
      setShowBackButton(false)
    }
  }, [showBackButton])

  useEffect(() => {
    if (actionButton) {
      setActionButton(actionButton)
    }
    return () => {
      setActionButton({icon: '', onClick: () => {}})
    }
  }, [actionButton])

  return <></>
}

const PageDescription: FC<WithChildren> = ({children}) => {
  const {setPageDescription} = usePageData()
  useEffect(() => {
    if (children) {
      setPageDescription(children.toString())
    }
    return () => {
      setPageDescription('')
    }
  }, [children])
  return <></>
}

export {PageDescription, PageTitle, PageDataProvider, usePageData}
