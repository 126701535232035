const getFingerprint = (userId = 0) => {
  const {
    userAgent,
    platform,
    language,
    hardwareConcurrency,
    maxTouchPoints,
    vendor,
  } = navigator;

  // Menggunakan informasi dari browser untuk menghasilkan string ID
  const data = [
    userAgent,
    platform,
    language,
    hardwareConcurrency,
    maxTouchPoints,
    vendor,
    userId,
  ].join('::');

  console.log('browser data:', data)

  // Hashing data untuk menghasilkan ID yang lebih pendek
  let hash = 0;
  for (let i = 0; i < data.length; i++) {
    const char = data.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Mengonversi ke 32bit integer
  }

  return hash.toString();
}

export default getFingerprint;