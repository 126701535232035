import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {useMutation} from 'react-query'
import {AxiosError} from 'axios'
import {useState} from 'react'
import {InputGroup} from 'react-bootstrap'
import {Link, useNavigate} from 'react-router-dom'
import {attend} from '../core/_request'
import AttendanceStudentSuccess from './AttendanceStudentSuccess'
import LoadingButtonWrapper from '../../../components/LoadingButtonWrapper'

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Username is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

export function AttendanceStudentForm({token}) {
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  const formik = useFormik({
    initialValues: {username: '', password: ''},
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        setLoading(true)
        const response = await attend(token, values)
        navigate('/attendance/success', {replace: true})
      } catch (error: any) {
        setStatus(error.message)
      } finally {
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible)
  }

  return (
    <>
      {isSuccess ? (
        <AttendanceStudentSuccess message='You successfully attended this class' />
      ) : (
        <form
          className='form w-100'
          onSubmit={formik.handleSubmit}
          noValidate
          id='kt_login_signin_form'
        >
          {formik.status ? (
            <div className='mb-lg-10 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          ) : (
            ''
          )}

          <div className='fv-row mb-4'>
            <label className='form-label fw-bolder text-dark fs-6'>Email</label>
            <input
              placeholder='Email'
              {...formik.getFieldProps('username')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.username && formik.errors.username},
                {
                  'is-valid': formik.touched.username && !formik.errors.username,
                }
              )}
              type='text'
              name='username'
              autoComplete='off'
              tabIndex={1}
            />
            {formik.touched.username && formik.errors.username && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.username}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-10'>
            <label className='form-label fw-bolder text-dark fs-6'>Password</label>
            <div className='position-relative'>
              <InputGroup>
                <input
                  placeholder='Password'
                  {...formik.getFieldProps('password')}
                  type={isPasswordVisible ? 'text' : 'password'}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': formik.touched.password && formik.errors.password,
                    },
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                  tabIndex={2}
                  autoComplete='off'
                />

                <InputGroup.Text role='button' onClick={togglePasswordVisibility}>
                  <i className={!isPasswordVisible ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                </InputGroup.Text>
              </InputGroup>

              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='d-grid mb-3'>
            <LoadingButtonWrapper isLoading={loading}>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-danger'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                <span className='indicator-label'>Attend</span>
              </button>
            </LoadingButtonWrapper>
          </div>
        </form>
      )}
    </>
  )
}
