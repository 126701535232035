const getBrowserType = () => {
  var userAgent = navigator.userAgent.toLowerCase()

  if (
    userAgent.indexOf('chrome') > -1 &&
    userAgent.indexOf('edg') == -1 &&
    userAgent.indexOf('opr') == -1
  ) {
    return 'Chrome'
  } else if (userAgent.indexOf('opr') > -1 || userAgent.indexOf('opera') > -1) {
    return 'Opera'
  } else if (userAgent.indexOf('firefox') > -1) {
    return 'Firefox'
  } else if (userAgent.indexOf('safari') > -1 && userAgent.indexOf('chrome') == -1) {
    return 'Safari'
  } else {
    return 'Other'
  }
}

export {getBrowserType}
