import {FC} from 'react'
import {useQuery} from 'react-query'
import {authByToken} from '../core/_request'
import {useNavigate} from 'react-router-dom'
import {showError} from '../../../../utils/toast'
import {parseJsonAndPascalToCamel} from '../../../../utils/array'
import {useAuth} from '../../../auth'

const ValidateTokenSeb: FC = () => {
  const query = new URLSearchParams(window.location.search)
  const token = query.get('token')

  const navigate = useNavigate()

  const {saveAuth, setCurrentUser} = useAuth()

  useQuery({
    retry: 0,
    cacheTime: 0,
    queryKey: ['validate-token-seb', token],
    queryFn: async () => authByToken(token ?? ''),
    onSuccess: (response) => {
      const {token, userInfo, classId, courseQuizId} = response.data

      const currentUser = {
        username: userInfo.loginName,
        email: userInfo.additionalInfo.email,
        majorName: userInfo.additionalInfo.studentMajorName,
        degreeName: userInfo.additionalInfo.studentMajorDegreeName,
        nim: userInfo.additionalInfo.studentMajorNim,
        id: userInfo.id,
        name: userInfo.name,
        roleId: userInfo.roles[0].id,
        roleName: userInfo.roles[0].name,
        limitFileSize: userInfo.additionalInfo.limitFileSize,
        limitFileSizeMb: userInfo.additionalInfo.limitFileSize / 1048576,
        staffMajors: userInfo.additionalInfo.staffMajorList
          ? parseJsonAndPascalToCamel(userInfo.additionalInfo.staffMajorList)
          : null,
      }

      setCurrentUser(currentUser)
      saveAuth({api_token: token})

      // if (cur) {
      window.location.href = `/safe-exam-browser/quiz/${courseQuizId}?cid=${classId}`
      // }
    },
    onError: (err: any) => {
      showError(err.message)
      // navigate('/')
    },
  })

  return (
    <div className='page-loading'>
      <div id='splash-screen' className='splash-screen d-block'>
        <div className='d-flex align-items-center justify-content-center h-100'>
          <div className='position-relative'>
            <div className='nb-spinner' style={{width: '130px', height: '130px'}}></div>
            <img src='/media/logos/logo.webp' className='dark-logo' alt='logo' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ValidateTokenSeb
