import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate, useLocation} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
// import DashboardWrapper from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
// import CoursePage from '../modules/courses/CoursePage'
// import StudentReportPage from '../modules/reports/students/StudentReportPage'
// import OtherReportPage from '../modules/reports/others/OtherReportPage'
// import LecturerReportPage from '../modules/reports/lecturers/LecturerReportPage'
// import {ReferenceListWrapper} from '../modules/reference/reference-list/ReferenceList'
import {MenuModel, useAuth} from '../modules/auth'
// import ArticlePage from '../modules/articles/ArticlePage'

const PrivateRoutes = () => {
  const UsersPage = lazy(() => import('../modules/user/UserPage'))
  const StudentPage = lazy(() => import('../modules/students/StudentPage'))
  const LecturerPage = lazy(() => import('../modules/lecturers/LecturerPage'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const SemesterPage = lazy(() => import('../modules/semester/SemesterPage'))
  // const CoursePage = lazy(() => import('../modules/courses/CoursePage'))
  const StaffPage = lazy(() => import('../modules/staff/StaffPage'))
  const SubjectPage = lazy(() => import('../modules/subjects/SubjectPage'))
  const AnnouncementPage = lazy(() => import('../modules/announcements/AnnouncementPage'))
  const ClassPage = lazy(() => import('../modules/classes/ClassPage'))
  const SchedulePage = lazy(() => import('../modules/schedules/SchedulePage'))
  const MaterialPage = lazy(() => import('../modules/materials/MaterialsPage'))
  const AcademicCalendarPage = lazy(
    () => import('../modules/academic-calendars/AcademicCalendarPage')
  )
  const ArchivePage = lazy(() => import('../modules/archive/ArchivePage'))
  const StudentSurveyPage = lazy(() => import('../modules/student-survey/StudentSurveyPage'))
  const RoomPage = lazy(() => import('../modules/rooms/RoomPage'))
  const MajorPage = lazy(() => import('../modules/majors/MajorPage'))
  const BibliographyPage = lazy(() => import('../modules/bibliography/BibliographyPage'))
  const DownloadRequestPage = lazy(() => import('../modules/download-request/DownloadRequestPage'))
  const UserRegistrationPage = lazy(
    () => import('../modules/user-registration/UserRegistrationPage')
  )
  const PublicUserPage = lazy(() => import('../modules/public-user/PublicUserPage'))
  const ReportPage = lazy(() => import('../modules/reports/ReportPage'))
  const ExternalLinkPage = lazy(() => import('../modules/external-link/ExternalLinkPage'))
  const ManageExternalLinkPage = lazy(
    () => import('../modules/external-link/ManageExternalLinkPage')
  )
  const DashboardWrapper = lazy(() => import('../pages/dashboard/DashboardWrapper'))
  const CoursePage = lazy(() => import('../modules/courses/CoursePage'))
  const StudentReportPage = lazy(() => import('../modules/reports/students/StudentReportPage'))
  const OtherReportPage = lazy(() => import('../modules/reports/others/OtherReportPage'))
  const LecturerReportPage = lazy(() => import('../modules/reports/lecturers/LecturerReportPage'))
  const ReferencePage = lazy(
    () => import('../modules/reference/ReferencePage')
  )
  const ArticlePage = lazy(() => import('../modules/articles/ArticlePage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route
          path='dashboard'
          element={
            <SuspensedView>
              <DashboardWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='user-session/*'
          element={
            <SuspensedView>
              <ProtectedRoute>
                <UsersPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='students/*'
          element={
            <SuspensedView>
              <ProtectedRoute>
                <StudentPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='lecturers/*'
          element={
            <SuspensedView>
              <ProtectedRoute>
                <LecturerPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='staff/*'
          element={
            <SuspensedView>
              <ProtectedRoute>
                <StaffPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='semester/*'
          element={
            <SuspensedView>
              <ProtectedRoute>
                <SemesterPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='courses/*'
          element={
            <ProtectedRoute>
              <SuspensedView>
                <CoursePage />
              </SuspensedView>
            </ProtectedRoute>
          }
        />
        <Route
          path='subjects/*'
          element={
            <SuspensedView>
              <ProtectedRoute>
                <SubjectPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='announcements/*'
          element={
            <SuspensedView>
              <ProtectedRoute>
                <AnnouncementPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='classes/*'
          element={
            <SuspensedView>
              <ProtectedRoute>
                <ClassPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='schedules/*'
          element={
            <SuspensedView>
              <ProtectedRoute>
                <SchedulePage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='materials/*'
          element={
            <SuspensedView>
              <ProtectedRoute>
                <MaterialPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='academic-calendars/*'
          element={
            <SuspensedView>
              <ProtectedRoute>
                <AcademicCalendarPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='reports/students/*'
          element={
            <SuspensedView>
              <ProtectedRoute>
                <StudentReportPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='reports/others/*'
          element={
            <SuspensedView>
              <ProtectedRoute>
                <OtherReportPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='reports/lecturers/*'
          element={
            <SuspensedView>
              <ProtectedRoute>
                <LecturerReportPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='archives/*'
          element={
            <SuspensedView>
              <ProtectedRoute>
                <ArchivePage />
              </ProtectedRoute>
            </SuspensedView>
          }
        ></Route>
        <Route
          path='student-survey/*'
          element={
            <SuspensedView>
              <StudentSurveyPage />
            </SuspensedView>
          }
        ></Route>
        <Route
          path='rooms/*'
          element={
            <SuspensedView>
              <ProtectedRoute>
                <RoomPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        ></Route>
        <Route
          path='major/*'
          element={
            <SuspensedView>
              <ProtectedRoute>
                <MajorPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        ></Route>
        <Route
          path='reference/*'
          element={
            <SuspensedView>
              <ProtectedRoute>
                <ReferencePage />
              </ProtectedRoute>
            </SuspensedView>
          }
        ></Route>
        <Route
          path='bibliography/*'
          element={
            <SuspensedView>
              <ProtectedRoute>
                <BibliographyPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        ></Route>
        <Route
          path='reports/*'
          element={
            <SuspensedView>
              <ProtectedRoute>
                <ReportPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='user-registration/*'
          element={
            <SuspensedView>
              <ProtectedRoute>
                <UserRegistrationPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        ></Route>
        <Route
          path='users/*'
          element={
            <SuspensedView>
              <ProtectedRoute>
                <PublicUserPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        ></Route>
        <Route
          path='download-request/*'
          element={
            <SuspensedView>
              <ProtectedRoute>
                <DownloadRequestPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='external-link/*'
          element={
            <SuspensedView>
              <ProtectedRoute>
                <ExternalLinkPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='manage-external-link/*'
          element={
            <SuspensedView>
              <ProtectedRoute>
                <ManageExternalLinkPage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        <Route
          path='articles/*'
          element={
            <SuspensedView>
              <ProtectedRoute>
                <ArticlePage />
              </ProtectedRoute>
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

const ProtectedRoute = ({redirectPath = '/error/404', children}) => {
  const {pathname} = useLocation()
  const {currentUser, currentMenu} = useAuth()
  if (!currentUser || !currentMenu) {
    return children
  }

  let isAllowed = isMenuAccessible(currentMenu, pathname)

  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />
  }

  return children
}

function canAccessMenu(menu: MenuModel, path: string) {
  const pattern = new RegExp(menu.route)
  if (pattern.test(path)) {
    return true
  }

  if (menu.children) {
    return menu.children.some((child) => canAccessMenu(child, path))
  }

  return false
}

function isMenuAccessible(menuData: MenuModel[], path: string) {
  return menuData.some((route) => canAccessMenu(route, path))
}

export {PrivateRoutes}
