import axios, {AxiosResponse} from 'axios'
import {IAuthRequest, IAuthResponse, IBaseResponse, OfficerRankModel, SatkerModel} from './_models'
import {OtpRequest} from './AuthHelpers'
import {CityQueryResponse} from '../../cities/cities-list/core/_models'
import {Response} from '../../../../_metronic/helpers'
import {Paging} from '../../../models/Response'

const API_URL = process.env.REACT_APP_BASE_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/users/validate-token`
export const LOGIN_URL = `${API_URL}/auth`
export const REGISTER_URL = `${API_URL}/user-registrations/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const GET_MENU = `${API_URL}/menus/bylogin`
export const GET_SATKER = `${API_URL}/satkers`
export const GET_OFFICER_RANK = `${API_URL}/officer-ranks`

async function auth(request: IAuthRequest): Promise<IBaseResponse<IAuthResponse>> {
  const formData = new URLSearchParams()
  formData.append('username', request.username)
  formData.append('password', request.password)
  formData.append('captchaToken', request?.captchaToken ?? '')
  formData.append('grantType', 'password')

  const response = await axios.post<IBaseResponse<IAuthResponse>>(LOGIN_URL, formData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
  return response.data
}

async function validateOtp(request: OtpRequest): Promise<IBaseResponse<boolean>> {
  const response = await axios.post<IBaseResponse<any>>(`${LOGIN_URL}/validate-otp`, request)
  return response.data
}

async function logoutAuth(): Promise<IBaseResponse<any>> {
  const response = await axios.post<IBaseResponse<any>>(`${LOGIN_URL}/logout`, {})

  return response.data
}

async function logoutSeb(): Promise<IBaseResponse<any>> {
  const response = await axios.post<IBaseResponse<any>>(`${LOGIN_URL}/logout-seb`, {})
  return response.data
}

export {auth, logoutAuth, validateOtp, logoutSeb}

// Server should return AuthModel
export function login(username: string, password: string) {
  const formData = new URLSearchParams()
  formData.append('username', username)
  formData.append('password', password)
  formData.append('grantType', 'password')

  return axios.post<any>(LOGIN_URL, formData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

// Server should return AuthModel
export function register(data) {
  return axios.post(REGISTER_URL, data)
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<any>(GET_USER_BY_ACCESSTOKEN_URL, {
    apiToken: token,
  })
}

export function getMenu() {
  return axios.get<any>(GET_MENU)
}

export const getSatkerLov = (query: string): Promise<Response<Paging<SatkerModel>>> => {
  return axios
    .get(`${GET_SATKER}/lov?${query}`)
    .then((d: AxiosResponse<Response<Paging<SatkerModel>>>) => d.data)
}

export const getOfficerRankLov = (query: string): Promise<Response<Paging<OfficerRankModel>>> => {
  return axios
    .get(`${GET_OFFICER_RANK}/lov?${query}`)
    .then((d: AxiosResponse<Response<Paging<OfficerRankModel>>>) => d.data)
}
