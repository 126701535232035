import {Children, cloneElement, FC, isValidElement} from 'react'

type Props = {
  isLoading: boolean
  loadingChild?: JSX.Element
  children: React.ReactNode
}

const LoadingButtonWrapper: FC<Props> = ({isLoading, loadingChild, children, ...props}) => {
  const modifiedChildren = Children.map(children, (child) => {
    if (
      isValidElement(child) &&
      (child.type === 'button' || (child.type as any).render.displayName === 'Button')
    ) {
      return cloneElement(
        child,
        {},
        isLoading
          ? loadingChild || (
              <span className='indicator-progress' style={{display: 'block'}}>
                <span className='spinner-border spinner-border-sm align-middle me-2'></span>
                Please wait...
              </span>
            )
          : child.props.children
      )
    }
    return child
  })

  return <>{modifiedChildren}</>
}

export default LoadingButtonWrapper
