import {useEffect, useState} from 'react'

const DateTimeDisplay = ({value, type, isDanger}) => {
  return (
    <div className={isDanger ? 'countdown danger' : 'countdown'}>
      <p className='fw-bold fs-1'>{value < 10 ? '0' + value : value.toString()}</p>
      {/* <span>{type}</span> */}
    </div>
  )
}

const ShowCounter = ({days, hours, minutes, seconds}) => {
  return (
    <div className='d-flex show-counter'>
      {/* <DateTimeDisplay value={days} type={'Days'} isDanger={days <= 3} /> */}
      {/* <DateTimeDisplay value={hours} type={'Hours'} isDanger={false} /> */}
      <DateTimeDisplay value={minutes} type={'Mins'} isDanger={false} />
      <span className='fw-bold fs-1 ms-1 me-1'>:</span>
      <DateTimeDisplay value={seconds} type={'Seconds'} isDanger={false} />
    </div>
  )
}

const CountdownTimer = ({targetDate, onCountdownEnd}) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate)
  if (days + hours + minutes + seconds <= 0) {
    setTimeout(() => {
      onCountdownEnd()
    }, 300)
    return <ShowCounter days={0} hours={0} minutes={0} seconds={0} />
  } else {
    return <ShowCounter days={days} hours={hours} minutes={minutes} seconds={seconds} />
  }
}

export default CountdownTimer

const useCountdown = (targetDate) => {
  const countDownDate = new Date(targetDate).getTime()

  const [countDown, setCountDown] = useState(countDownDate - new Date().getTime())

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime())
    }, 1000)

    return () => clearInterval(interval)
  }, [countDownDate])

  return getReturnValues(countDown)
}

const getReturnValues = (countDown) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24))
  const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000)

  return [days, hours, minutes, seconds]
}

export {useCountdown}
