import {FC, ReactElement, ReactNode} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {Placement} from 'react-bootstrap/types'

type Props = {
  title: string
  children: ReactElement,
  placement?: Placement
}

const OverlayTooltip: FC<Props> = ({title, placement, children}) => {

  return <OverlayTrigger
    placement={placement}
    delay={{ show: 250, hide: 400 }}
    overlay={(props) => {
      return <Tooltip {...props}>{title}</Tooltip>
    }}>
    {children}
  </OverlayTrigger>
}

export {OverlayTooltip}