// Utility to convert string to ArrayBuffer
const stringToArrayBuffer = (str: string): ArrayBuffer => {
  const encoder = new TextEncoder()
  return encoder.encode(str)
}

// Utility to convert ArrayBuffer to string
const arrayBufferToString = (buffer: ArrayBuffer): string => {
  const decoder = new TextDecoder()
  return decoder.decode(buffer)
}

// Utility to convert Base64 string to ArrayBuffer
const base64ToArrayBuffer = (base64: string): ArrayBuffer => {
  const binaryString = atob(base64)
  const len = binaryString.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return bytes.buffer
}

// Utility to convert ArrayBuffer to Base64 string
const arrayBufferToBase64 = (buffer: ArrayBuffer): string => {
  const bytes = new Uint8Array(buffer)
  let binary = ''
  bytes.forEach((b) => (binary += String.fromCharCode(b)))
  return btoa(binary)
}

// Function to import AES key from Base64 string
const importKeyFromBase64 = async (base64Key: string): Promise<CryptoKey> => {
  const rawKey = base64ToArrayBuffer(base64Key)
  return window.crypto.subtle.importKey(
    'raw',
    rawKey,
    {name: 'AES-CBC'}, // Use AES-CBC here
    true,
    ['encrypt', 'decrypt']
  )
}

// Function to encrypt text using AES-CBC
const encryptAES = async (text: string, base64Key: string, base64Iv: string): Promise<string> => {
  const key = await importKeyFromBase64(base64Key)
  const iv = new Uint8Array(base64ToArrayBuffer(base64Iv))
  const encodedText = stringToArrayBuffer(text)

  const encryptedData = await window.crypto.subtle.encrypt(
    {
      name: 'AES-CBC',
      iv: iv,
    },
    key,
    encodedText
  )

  return arrayBufferToBase64(encryptedData)
}

// Function to decrypt text using AES-CBC
const decryptAES = async (
  encryptedText: string,
  base64Key: string,
  base64Iv: string
): Promise<string> => {
  const key = await importKeyFromBase64(base64Key)
  const iv = new Uint8Array(base64ToArrayBuffer(base64Iv))
  const encryptedBuffer = base64ToArrayBuffer(encryptedText)

  const decryptedData = await window.crypto.subtle.decrypt(
    {
      name: 'AES-CBC',
      iv: iv,
    },
    key,
    encryptedBuffer
  )

  return arrayBufferToString(decryptedData)
}

function encryptToBase64(text: any) {
  return btoa(text) // Converts the string to Base64
}

function decryptFromBase64(base64Text: string) {
  return atob(base64Text) // Converts the Base64 back to string
}

export {encryptAES, decryptAES, encryptToBase64, decryptFromBase64}
