import {FC, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'

type Props = {
  show: boolean
  onClose(): void
}

const SurveyNotificationModal: FC<Props> = ({show, onClose}) => {
  const handleClose = () => {
    onClose()
  }

  return (
    <Modal show={show} onHide={handleClose} size='sm' className='modal-notif-survey'>
      <Modal.Header closeButton>
        <Modal.Title>Notifikasi Survey</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex flex-column align-items-center justify-center'>
          <KTIcon iconName='notification-status' className='fs-5x mb-6' />
          <p className='h4 text-center mb-6'>Anda masih memiliki survei yang belum diisi.</p>
          <p className='text-center fs-7 text-gray-700'>
            Mohon untuk melengkapi survei tersebut agar Anda dapat melakukan absensi di kelas
            selanjutnya
          </p>
          <Link to='/student-survey' onClick={handleClose}>
            <Button type='button' variant='primary' size='sm' className='mt-4 mb-4'>
              Lihat Daftar Survey
            </Button>
          </Link>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default SurveyNotificationModal
