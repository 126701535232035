const iosDeviceMapping = new Map([
  ['320x480', 'IPhone 4S, 4, 3GS, 3G, 1st gen'],
  ['320x568', 'IPhone 5, SE 1st Gen,5C, 5S'],
  ['375x667', 'IPhone SE 2nd Gen, 6, 6S, 7, 8'],
  ['375x812', 'IPhone X, XS, 11 Pro, 12 Mini, 13 Mini'],
  ['390x844', 'IPhone 13, 13 Pro, 12, 12 Pro'],
  ['414x736', 'IPhone 8+'],
  ['414x896', 'IPhone 11, XR, XS Max, 11 Pro Max'],
  ['428x926', 'IPhone 13 Pro Max, 12 Pro Max'],
  ['476x847', 'IPhone 7+, 6+, 6S+'],
  ['744x1133', 'IPad Mini 6th Gen'],
  [
    '768x1024',
    'IPad Mini (5th Gen), IPad (1-6th Gen), iPad Pro (1st Gen 9.7), Ipad Mini (1-4), IPad Air(1-2)  ',
  ],
  ['810x1080', 'IPad 7-9th Gen'],
  ['820x1180', 'iPad Air (4th gen)'],
  ['834x1194', 'iPad Pro (3-5th Gen 11)'],
  ['834x1112', 'iPad Air (3rd gen), iPad Pro (2nd gen 10.5)'],
  ['1024x1366', 'iPad Pro (1-5th Gen 12.9)'],
])

const desktopDeviceMapping = new Map([
  ['Win32', 'Windows'],
  ['Linux', 'Linux'],
  ['MacIntel', 'MacOS'],
  ['Linux x86_64', 'Linux'],
])

const getAndroidDeviceName = () => {
  const androidUserAgentString = window.navigator.userAgent.slice(
    window.navigator.userAgent.indexOf('Android')
  )
  const androidDeviceName = androidUserAgentString.slice(
    androidUserAgentString.indexOf('; ') + 1,
    androidUserAgentString.indexOf(')')
  )
  if (androidDeviceName) {
    return androidDeviceName.trim().split(' ')[0]
  }

  return 'Android'
}

const getIosDeviceName = () => {
  const screenResolution = `${window.screen.width}x${window.screen.height}`
  const device = iosDeviceMapping.get(screenResolution)
  if (device) {
    return device
  }
  return 'Iphone'
}

const getDesktopDeviceName = (): 'Unknown' | 'Windows' | 'Linux' | 'MacOS' | string => {
  const platform = navigator?.platform || 'unknown'
  const device = desktopDeviceMapping.get(platform) ?? 'Unknown'
  return device
}

export default function getDeviceName():
  | 'Unknown'
  | 'Windows'
  | 'Linux'
  | 'MacOS'
  | string
  | 'Android'
  | 'Iphone' {
  const isMobileDevice = window.navigator.userAgent.toLowerCase().includes('mobi')

  if (!isMobileDevice) {
    return getDesktopDeviceName()
  }

  return window.navigator.userAgent.includes('Android') ? 'Android' : 'Iphone'
}
