import Countdown from 'react-countdown'
import HeaderQuizInfo from './QuizHeaderInfo'
import {QuizAnswerStatusEnum} from '../../../../models/IClassQuiz'
import CountDown from './CountDownTpl'
import clsx from 'clsx'

const QuizHeader = ({
  quizHeader,
  isReadOnly,
  infiniteTime,
  countdown,
  handleCompleteCountdown,
  countdownKey,
  data,
  isStudent,
}) => {
  const renderCountdown = ({hours, minutes, seconds, completed}) => {
    return <CountDown completed={completed} hours={hours} minutes={minutes} seconds={seconds} />
  }

  return (
    <div className='row g-5'>
      <div className='col-12'>
        <div className='card'>
          <div className='card-body py-9'>
            <div className='row align-items-center justify-content-between'>
              <HeaderQuizInfo
                quizName={quizHeader?.quizName}
                nim={quizHeader?.nim}
                studentName={quizHeader?.studentName}
                subjectName={quizHeader?.subjectName}
              />
              <div
                className={clsx(
                  'col-4 mt-2 mt-md-0 d-flex align-items-center justify-content-end',
                  // quizHeader?.useTimerPerQuestion ? 'd-none' : 'd-flex'
                )}
              >
                {!isReadOnly && !infiniteTime && (
                  <Countdown
                    date={countdown}
                    onComplete={handleCompleteCountdown}
                    renderer={renderCountdown}
                    key={countdownKey}
                  ></Countdown>
                )}
                {isReadOnly &&
                  data.statusId === +QuizAnswerStatusEnum.Completed &&
                  data.isPublished &&
                  (!data.isExam || !isStudent) && (
                    <div className='bg-light-success d-flex flex-column text-success px-6 py-2 rounded-2 align-items-center'>
                      <div className='fs-6'>Score</div>
                      <div className='fs-2 fw-bold'>
                        {data.totalScore} / {data.maxScore}
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QuizHeader
