import axios from 'axios'
import {IBaseResponse} from '../../auth'
import {ForgotPasswordRequestType, ResetPasswordRequestType} from './_model'

const API_URL = process.env.REACT_APP_BASE_API_URL
const FORGOT_PWD = `${API_URL}/users/forgot-password`
const VALIDATE = `${API_URL}/users/validate-user-token`
const RESET = `${API_URL}/users/reset-password-token`

const sendRequestForgotPassword = async (
  request: ForgotPasswordRequestType
): Promise<IBaseResponse<any>> => {
  const response = await axios.post<IBaseResponse<any>>(FORGOT_PWD, request)
  return response.data
}

const validateUserToken = async (token: string): Promise<IBaseResponse<any>> => {
  const response = await axios.get<IBaseResponse<any>>(`${VALIDATE}/${token}`)
  return response.data
}

const resetPasswordToken = async (
  request: ResetPasswordRequestType
): Promise<IBaseResponse<any>> => {
  const response = await axios.put<IBaseResponse<any>>(RESET, request)
  return response.data
}

export {sendRequestForgotPassword, validateUserToken, resetPasswordToken}
