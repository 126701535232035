import Countdown from 'react-countdown'
import SafeHTMLRenderer from '../../../../components/SafeHtmlRenderer'
import {useEffect, useState} from 'react'
import CountDownTpl from './CountDownTpl'

const QuizQuestion = ({
  showedQuestion,
  showedIndex,
  remainingSecond,
  isStudent,
  isReadOnly,
  data,
  infiniteTime,
  handleNextClick,
  useTimerPerQuestion,
}) => {
  const [countdown, setCountdown] = useState(Date.now() + remainingSecond)
  const [countdownKey, setCountdownKey] = useState('')
  const [isStarted, setIsStarted] = useState(false)

  const handleCompleteCountdown = () => {
    handleNextClick()
  }

  const renderCountdown = ({hours, minutes, seconds, completed}) => {
    return <CountDownTpl completed={completed} hours={hours} minutes={minutes} seconds={seconds} />
  }

  useEffect(() => {
    if (!useTimerPerQuestion) return
    if (remainingSecond.id !== showedQuestion?.id) {
      return
    }

    if (remainingSecond.timer !== null) {
      if (remainingSecond.timer < 0) {
        handleNextClick()
        setCountdown(0)
        return
      }

      setIsStarted(true)
      setCountdown(Date.now() + remainingSecond.timer)
      setCountdownKey(Date.now().toString())
    }
  }, [useTimerPerQuestion, remainingSecond, showedQuestion?.id])

  return (
    <>
      <div className='d-flex flex-row align-items-start'>
        <div className='fs-4 d-flex fw-bold flex-row-fluid'>
          <span className='me-2'>{(showedIndex || 0) + 1}.</span>{' '}
          <SafeHTMLRenderer className='user-select-none' htmlString={showedQuestion.questionText} />
        </div>

        {((isStudent && !data.isExam) || !isStudent) && (
          <div className='fw-bold w-100px ms-2 text-end'>
            Score: {isReadOnly && (showedQuestion.answerScore || 0) + ' / '}
            {showedQuestion.correctScore}
          </div>
        )}
        <div className='d-flex align-items-center ms-5'>
          {useTimerPerQuestion && !isReadOnly && !infiniteTime && (
            <Countdown
              date={countdown}
              onComplete={handleCompleteCountdown}
              renderer={renderCountdown}
              key={countdownKey}
              autoStart={isStarted}
            ></Countdown>
          )}
        </div>
      </div>
    </>
  )
}

export default QuizQuestion
