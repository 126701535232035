import {Route, Routes} from 'react-router-dom'
import {SurveyLayout} from './SurveyLayout'
import {SurveyAnswer} from './components/SurveyAnswer'
import SurveyAnswerSuccess from './components/SurveyAnswerSuccess'

const SurveyPage = () => (
  <Routes>
    <Route element={<SurveyLayout />}>
      <Route path='/:token' element={<SurveyAnswer />} />
      <Route
        path='/success'
        element={
          <>
            <SurveyAnswerSuccess message='You successfully survey this meet' />
          </>
        }
      />
    </Route>
  </Routes>
)

export {SurveyPage}
