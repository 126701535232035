import clsx from 'clsx'
import SafeHTMLRenderer from '../../../../components/SafeHtmlRenderer'
import {formatDateTime} from '../../../../utils/date'

const QuizEssayText = ({
  isAssess,
  isReadOnly,
  showedQuestion,
  textRef,
  isLoading,
  handleChangeText,
  correctRef,
  handleChangeCorrect,
  scoreRef,
  handleChangeScore,
  handleSaveDraft,
  isLoadingDraft,
}) => {
  const maxLength = 10000

  return (
    <>
      <div className='d-flex flex-row align-items-stretch mt-5'>
        <div className='flex-row-fluid w-50'>
          <div key={'essay'} className='form-group'>
            {isAssess && <label className='form-label'>Student Answer</label>}
            <textarea
              className={clsx(
                'form-control',
                {
                  'is-invalid': isReadOnly && !isAssess && showedQuestion.isCorrect === false,
                },
                {
                  'is-valid': isReadOnly && !isAssess && showedQuestion.isCorrect,
                },
                {
                  'border-danger': showedQuestion?.isUnsavedChange,
                }
              )}
              rows={10}
              ref={textRef}
              id={'question-' + showedQuestion.id}
              value={showedQuestion.answerEssay || ''}
              readOnly={isReadOnly}
              disabled={isLoading}
              maxLength={maxLength}
              onChange={() => {
                if (!isReadOnly) {
                  handleChangeText()
                }
              }}
            />
            <div className='d-flex justify-content-between py-4'>
              <div className='d-flex'>
                <div className='me-8'>
                  <div className='fs-8'>Characters</div>
                  <span className='fw-bold'>
                    <span
                      className={clsx({
                        'text-danger': showedQuestion?.answerEssay?.length >= maxLength,
                      })}
                    >
                      {showedQuestion?.answerEssay?.length ?? 0}
                    </span>{' '}
                    / {maxLength}
                  </span>
                </div>
                {!isReadOnly && (
                  <div>
                    <div className='fs-8'>Last Saved</div>
                    <span className='fw-bold'>
                      {showedQuestion?.endAnswerAt
                        ? formatDateTime(showedQuestion?.endAnswerAt)
                        : '-'}
                    </span>
                  </div>
                )}
              </div>
              <div className='d-flex align-items-center'>
                <div className='mx-4'>
                  {isLoadingDraft && <i className='fas fa-spinner fa-spin'></i>}
                </div>
                {!isReadOnly && (
                  <button
                    type='button'
                    className='btn btn-outline btn-outline-warning btn-sm'
                    onClick={handleSaveDraft}
                    disabled={isLoadingDraft}
                  >
                    Save Draft
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        {isAssess && (
          <>
            <div className='flex-row-fluid w-50 ms-5'>
              <div key={'correct-essay'} className='form-group d-flex flex-column h-100'>
                <label className='form-label'>Correct Answer</label>
                <div className='form-control flex-column-fluid'>
                  <SafeHTMLRenderer htmlString={showedQuestion.correctAnswerEssay || ''} />
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {isAssess && (
        <div className='mt-5 card'>
          <div className='card-body'>
            <div key={'correct-essay'} className='form-group'>
              <label className='form-label'>Is Correct?</label>
              <div className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  checked={showedQuestion.isCorrect || false}
                  ref={correctRef}
                  disabled={isLoading}
                  onChange={handleChangeCorrect}
                />
              </div>
            </div>

            <div key={'score'} className='form-group w-200px mt-5'>
              <label className='form-label'>Score</label>
              <input
                className='form-control'
                type='number'
                id={'score-' + showedQuestion.id}
                ref={scoreRef}
                disabled={isLoading}
                value={showedQuestion.answerScore || 0}
                onChange={handleChangeScore}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default QuizEssayText
