/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const SurveyLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid flex-lg-row'
      style={{
        backgroundImage: `url('${toAbsoluteUrl('/media/bg-7.webp')}')`,
        backgroundPosition: 'bottom right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundColor: '#390d0a',
        alignItems: 'center',
      }}
    >
      <div className='d-flex flex-column flex-column-fluid flex-lg-row h-100 w-100 justify-content-center'>
        <div className='d-flex align-items-center p-8 p-md-20 justify-content-center'>
          <div className='bg-body d-flex flex-column flex-md-row align-items-stretch flex-center rounded-4 w-800px overflow-hidden'>
            <div className='d-flex flex-center flex-column p-14 p-md-20 pt-md-10 pt-10'>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {SurveyLayout}
