import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Alert, Button} from 'react-bootstrap'
import screenfull from 'screenfull'
import {QuizAnswerStatusEnum} from '../../../models/IClassQuiz'
import {createFraud, updateEndFraud, validateFraudPasscode} from '../core/_request'
import {getBrowserType} from '../../../utils/browser'
import QuizFraudEnum from '../../../enums/QuizFraudEnum'
import {showError} from '../../../utils/toast'
import CameraCapture from '../../../components/CameraCapture'
import LoadingButtonWrapper from '../../../components/LoadingButtonWrapper'
import getDeviceName from '../../../utils/getDevicePlatform'
import QuizAlertBrowser from './components/QuizAlertBrowser'
import QuizAlertOS from './components/QuizAlertOS'
import {useStopwatch} from 'react-timer-hook'
import moment from 'moment/moment'
import CountDownTpl from './components/CountDownTpl'
import isSafeExamBrowser from '../../../utils/seb'

type IExamFraud = {
  total: number
  changeTab: number
  exitFullScreen: number
}

interface Prop {
  header: any
  children: any
  onStart: any
  onExamStatChange: any
  onStartWithPicture: any
  nim: any
  statusId: any
  quizId: any
  showedQuestion: any
  isStudent: boolean | null
  isExamFinished: boolean | null
  isNew: boolean | null
  isStrictQuiz: boolean | null
  allowedBrowsers: string[]
  answerId: number
  canUnlock: boolean
  isUseCamera: boolean
  onErrorFromWrapper(message: string): void
  allowedOs: string[]
  // fraudPassCode: string | null
  isExam: boolean | null
  lastFraudStartDate?: string
  totalFraud?: number
}

const STUDENT_QUIZ_KEY = 'sq-key'

const setStudentQuizKey = (quizId: number, value: any) => {
  try {
    const lsValue = btoa(JSON.stringify(value))
    localStorage.setItem(`${STUDENT_QUIZ_KEY}-${quizId}`, lsValue)
  } catch (e) {}
}

const getStudentQuizKey = (quizId: number) => {
  try {
    const res = localStorage.getItem(`${STUDENT_QUIZ_KEY}-${quizId}`)
    if (!res) return null

    return JSON.parse(atob(res))
  } catch (e) {
    return null
  }
}

const removeStudentQuizKey = (quizId: number) => {
  localStorage.removeItem(`${STUDENT_QUIZ_KEY}-${quizId}`)
}

const QuizWrapper = ({
  header,
  children,
  onStart,
  onStartWithPicture,
  nim,
  statusId,
  quizId,
  showedQuestion,
  isStudent,
  isExamFinished,
  isNew = null,
  isStrictQuiz = null,
  allowedBrowsers = [],
  allowedOs = [],
  answerId,
  canUnlock,
  isUseCamera,
  onErrorFromWrapper,
  onExamStatChange,
  isExam,
  lastFraudStartDate,
  totalFraud,
}: // fraudPassCode = null,
Prop) => {
  const [fraud, setFraud] = useState<IExamFraud>({
    total: 0,
    changeTab: 0,
    exitFullScreen: 0,
  })
  const divRef = useRef<HTMLDivElement>(null)
  const [isExamStarted, setIsExamStarted] = useState(false)
  const [fraudDetected, setFraudDetected] = useState(false)
  const [fraudNim, setFraudNim] = useState<string>('')
  const [isAllowedBrowser, setIsAllowedBrowser] = useState<boolean | null>(null)
  const [isAllowedOs, setIsAllowedOs] = useState<boolean | null>(null)
  const [isValidateLoading, setValidateLoading] = useState<boolean>(false)
  const [captured, setCaptured] = useState(false)
  const [image, setImage] = useState<File | Blob>()
  const [useCamera, setUseCamera] = useState(false)
  const [canCapture, setCanCapture] = useState(false)
  const [isCameraError, setIsCameraError] = useState(false)
  const [faceDetected, setFaceDetected] = useState(false)
  const [loadingFaceDetection, setLoadingFaceDetection] = useState(false)
  const [fraudCount, setFraudCount] = useState(totalFraud)
  const useFaceDetection = false

  const {reset, hours, seconds, minutes, isRunning} = useStopwatch({autoStart: false})

  const enableFullscreen = async () => {
    if (isSafeExamBrowser()) {
      return
    }

    if (screenfull.isEnabled && divRef.current) {
      try {
        await screenfull.request()
      } catch (error) {
        onErrorFromWrapper('Fullscreen request failed')
        // throw new Error('Data is required!')
        // console.error('Fullscreen request failed', error)
        alert('Fullscreen request failed. Please try again.')
      }
    }
  }

  useEffect(() => {
    if (isSafeExamBrowser()) {
      _startExam()
    }
  }, [])

  useEffect(() => {
    if ((isNew || canUnlock) && isStrictQuiz && isUseCamera) {
      setUseCamera(true)
    }
  }, [isStrictQuiz, isNew, canUnlock, isUseCamera])

  useEffect(() => {
    if (isSafeExamBrowser()) {
      return
    }

    if (isStrictQuiz) {
      const targetDiv = document.getElementById('kt_app_body')

      if (isExamStarted) {
        // fullscreen fraud
        const handleFullscreenChange = (e) => {
          try {
            if (screenfull.isFullscreen) {
              document.body.classList.add('fullscreen-mode')
            } else {
              document.body.classList.remove('fullscreen-mode')
            }

            if (!screenfull.isFullscreen) {
              setFraudDetected(true)
              setIsExamStarted(false)
              console.log(showedQuestion)

              createFraud(quizId, showedQuestion?.id, QuizFraudEnum.ExitFullscreen)
                .then((res) => {
                  if (res.data) {
                    const milliseconds = moment().diff(moment(res.data.startDate), 'milliseconds')
                    const offset = moment().add(milliseconds, 'milliseconds').toDate()
                    reset(offset, true)
                    setFraudCount(res.data.totalFraud)
                  }
                })
                .catch((e) => {
                  showError(e.message)
                })

              setFraud((prev) => ({
                ...prev,
                exitFullScreen: prev.exitFullScreen++,
                total: prev.total++,
              }))
            }
          } catch (e: any) {
            showError(e.message)
          }
        }

        // handle blur
        const handleBlurChange = () => {
          if (isSafeExamBrowser()) {
            return
          }

          try {
            document.body.classList.remove('fullscreen-mode')

            console.log('You have switched away from the exam. Your exam may be invalidated.')
            setIsExamStarted(false)
            setFraudDetected(true)

            createFraud(quizId, showedQuestion?.id, QuizFraudEnum.Blur)
              .then((res) => {
                if (res.data) {
                  const milliseconds = moment().diff(moment(res.data.startDate), 'milliseconds')
                  const offset = moment().add(milliseconds, 'milliseconds').toDate()
                  reset(offset, true)
                  setFraudCount(res.data.totalFraud)
                }
              })
              .catch((e) => {
                showError(e.message)
              })

            setFraud((prev) => ({
              ...prev,
              changeTab: prev.changeTab++,
              total: prev.total++,
            }))
            if (screenfull.isEnabled) {
              screenfull.exit()
            }
          } catch (e: any) {
            showError(e.message)
          }
        }

        // handle blur
        const handleClickOutsideApp = () => {
          if (isSafeExamBrowser()) {
            return
          }

          try {
            document.body.classList.remove('fullscreen-mode')

            console.log('You are not on our app anymore. Your exam may be invalidated.')
            setIsExamStarted(false)
            setFraudDetected(true)

            createFraud(quizId, showedQuestion?.id, QuizFraudEnum.OutsideApp)
              .then((res) => {
                if (res.data) {
                  const milliseconds = moment().diff(moment(res.data.startDate), 'milliseconds')
                  const offset = moment().add(milliseconds, 'milliseconds').toDate()
                  reset(offset, true)
                  setFraudCount(res.data.totalFraud)
                }
              })
              .catch((e) => {
                showError(e.message)
              })

            setFraud((prev) => ({
              ...prev,
              changeTab: prev.changeTab++,
              total: prev.total++,
            }))
            if (screenfull.isEnabled) {
              screenfull.exit()
            }
          } catch (e: any) {
            showError(e.message)
          }
        }

        // prevent right click
        const handleContextMenu = (event) => {
          event.preventDefault()
        }

        const handleKeyDown = (event) => {
          if (event.ctrlKey && (event.key === 'c' || event.key === 'v')) {
            event.preventDefault() // Prevent the default action for Ctrl+C and Ctrl+V
          }
        }

        const handleClickOutside = (event) => {
          // console.log(targetDiv)
          if (
            !targetDiv?.contains(event.target) &&
            !event.target.closest(`#startExamBtn`) &&
            !event.target.closest(`#resumeExamBtn`)
          ) {
            console.log('Clicked outside the div!')
            handleClickOutsideApp()
          }
        }

        document.addEventListener('keydown', handleKeyDown)

        if (screenfull.isEnabled) {
          screenfull.on('change', handleFullscreenChange)
        }

        window.addEventListener('blur', handleBlurChange)
        document.addEventListener('contextmenu', handleContextMenu)
        document.addEventListener('click', handleClickOutside)

        return () => {
          if (screenfull.isEnabled) {
            screenfull.off('change', handleFullscreenChange)
          }
          window.removeEventListener('blur', handleBlurChange)
          document.removeEventListener('contextmenu', handleContextMenu)
          document.removeEventListener('keydown', handleKeyDown)
          document.removeEventListener('click', handleClickOutside)
        }
      }
    }
  }, [
    isExamStarted,
    createFraud,
    quizId,
    setFraud,
    setFraudDetected,
    setIsExamStarted,
    isStrictQuiz,
    showedQuestion,
  ])

  useEffect(() => {
    if (lastFraudStartDate && isNew === false) {
      const milliseconds = moment().diff(moment(lastFraudStartDate), 'milliseconds')
      const offset = moment().add(milliseconds, 'milliseconds').toDate()
      reset(offset, true)
      setFraudCount(totalFraud)
    } else if (isNew === false && statusId === +QuizAnswerStatusEnum.Processed) {
      const lastQuestionIdStr = localStorage.getItem('lastQuestionId')
      let lastQuestionId = 0
      if (lastQuestionIdStr) {
        lastQuestionId = +lastQuestionIdStr
      }

      if (lastQuestionId) {
        createFraud(quizId, lastQuestionId, QuizFraudEnum.Restart)
          .then((res) => {
            if (res.data) {
              const milliseconds = moment().diff(moment(res.data.startDate), 'milliseconds')
              const offset = moment().add(milliseconds, 'milliseconds').toDate()
              reset(offset, true)
              setFraudCount(res.data.totalFraud)
            }
          })
          .catch((e) => {
            showError(e.message)
          })
      }
    }
  }, [lastFraudStartDate, reset, isNew, quizId, totalFraud])

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem('isRefreshed', !fraudDetected + '')
    }

    const isRefreshed = localStorage.getItem('isRefreshed')
    console.log(isRefreshed)

    if (isRefreshed && isRefreshed === 'true') {
      console.log('Page was refreshed')
      localStorage.removeItem('isRefreshed')
    } else {
      console.log('Page loaded normally')
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [fraudDetected])

  useEffect(() => {
    if (showedQuestion && showedQuestion.id > 0) {
      setStudentQuizKey(quizId, {
        lastQuestionId: showedQuestion.id,
      })
    }
  }, [showedQuestion])

  // useEffect(() => {
  //   if (isStrictQuiz) {
  //     const handleFocusChange = () => {
  //       if (isStudent && statusId === +QuizAnswerStatusEnum.Processed)
  //         updateEndFraud(quizId).then((res) => {})
  //     }
  //
  //     window.addEventListener('focus', handleFocusChange)
  //
  //     return () => {
  //       window.removeEventListener('focus', handleFocusChange)
  //     }
  //   }
  // }, [isStrictQuiz, quizId, updateEndFraud, isStudent, statusId])

  // const onNimFocus = () => {
  //   updateEndFraud(quizId).then((res) => {})
  // }

  useEffect(() => {
    if (isStudent === false || isStrictQuiz === false) {
      onStart()
    }
  }, [isStudent, isStrictQuiz])

  const _startExam = () => {
    enableFullscreen()
    setIsExamStarted(true)
    setFraudNim('')
    setFraudDetected(false)
    if (useCamera) {
      onStartWithPicture(image)
      setUseCamera(false)
    } else {
      onStart(getStudentQuizKey(quizId)?.lastQuestionId)
    }
  }

  const startExam = async (fraud: boolean, code: string) => {
    if (!fraud) {
      _startExam()
      return
    }

    if (fraud) {
      try {
        setValidateLoading(true)
        const resp = await validateFraudPasscode(quizId, code)

        if ((resp.data ?? false) === false) {
          showError(resp.message ?? 'Invalid', 2000)
          return
        }
        _startExam()
        if (answerId) {
          updateEndFraud(quizId).then((res) => {})
        }
      } catch (err: any) {
        if (err?.code === '1012') {
          onErrorFromWrapper(err?.message)
        }
      } finally {
        setValidateLoading(false)
      }
    }
  }

  useEffect(() => {
    if (isStrictQuiz) {
      if (isExamFinished) {
        removeStudentQuizKey(quizId)
        setIsExamStarted(false)

        if (!isSafeExamBrowser()) {
          document.body.classList.remove('fullscreen-mode')
          if (screenfull.isEnabled) {
            screenfull.exit()
          }
        }
      }
    }
  }, [isExamFinished, isStrictQuiz])

  useEffect(() => {
    if (isExam) {
      if (isSafeExamBrowser()) {
        return
      }

      if (allowedBrowsers !== null) {
        var browserType = getBrowserType()
        setIsAllowedBrowser(allowedBrowsers.length === 0 || allowedBrowsers.includes(browserType))
        // console.log('The browser is: ' + browserType)
      } else {
        setIsAllowedBrowser(null)
      }
    } else {
      setIsAllowedBrowser(true)
    }
  }, [allowedBrowsers, isExam])

  useEffect(() => {
    if (isExam) {
      if (isSafeExamBrowser()) {
        return
      }

      if (allowedOs !== null) {
        const os = getDeviceName()
        setIsAllowedOs(allowedOs.length === 0 || allowedOs.includes(os))
        // console.log('The OS is ' + os)
      } else {
        setIsAllowedOs(null)
      }
    } else {
      setIsAllowedOs(true)
    }
  }, [allowedOs, isExam])

  useEffect(() => {
    if (quizId && statusId && statusId > +QuizAnswerStatusEnum.Processed) {
      removeStudentQuizKey(quizId)
    }
  }, [statusId, quizId])

  const onEnterSubmit = (event) => {
    if (event.key === 'Enter') {
      if (!useCamera || image) {
        startExam(true, fraudNim)
      }
    }
  }

  const handleErrorCamera = (err: Error) => {
    showError(err.message)
    setIsCameraError(true)
  }

  const handleRetake = () => {
    setImage(undefined)
    setFaceDetected(false)
    setCaptured(false)
  }

  return (
    <div
      ref={divRef}
      style={{backgroundColor: 'var(--bs-app-bg-color)', padding: isExamStarted ? 24 : 0}}
    >
      <div className='mb-5'>{header}</div>
      {allowedBrowsers !== null && (
        <>
          {!isAllowedBrowser && (
            <div>
              <QuizAlertBrowser allowedBrowsers={allowedBrowsers} />
            </div>
          )}
          {!isAllowedOs && (
            <div>
              <QuizAlertOS allowedOs={allowedOs} />
            </div>
          )}
          {isAllowedBrowser && isAllowedOs && (
            <>
              {isStrictQuiz && (
                <>
                  {statusId < +QuizAnswerStatusEnum.NeedCalculate && !isExamFinished && (
                    <>
                      <div>
                        {!isExamStarted &&
                          !fraudDetected &&
                          (isNew || statusId === +QuizAnswerStatusEnum.Draft) && (
                            <div
                              className='card justify-content-center align-items-center'
                              style={{minHeight: 400}}
                            >
                              <div className='d-flex justify-content-center my-10'>
                                <div className='d-flex flex-column justify-content-center'>
                                  <Alert variant='danger mb-10 p-10 text-danger'>
                                    <h1 className='text-center mb-6 text-danger'>Warning !!!</h1>
                                    <ul className='fs-1'>
                                      <li>Do not exit fullscreen during exam</li>
                                      <li>Do not move to other window during exam</li>
                                      <li>Do not reload page during exam</li>
                                    </ul>
                                  </Alert>

                                  {useCamera && (
                                    <div className='mb-10 text-center'>
                                      <CameraCapture
                                        isCaptured={captured}
                                        setImage={setImage}
                                        onError={(err) => handleErrorCamera(err)}
                                        setCanCapture={setCanCapture}
                                        useFaceDetection={useFaceDetection}
                                        setIsCaptured={setCaptured}
                                        setIsFaceDetected={setFaceDetected}
                                        setIsLoadingFaceDetection={setLoadingFaceDetection}
                                        width={200}
                                        height={200}
                                      />
                                      <div className='mt-4 d-flex justify-content-center'>
                                        {!captured ? (
                                          <LoadingButtonWrapper isLoading={!canCapture}>
                                            <button
                                              className='btn btn-primary'
                                              type='button'
                                              onClick={() => canCapture && setCaptured(true)}
                                              disabled={!canCapture || isCameraError}
                                            >
                                              Take Picture
                                            </button>
                                          </LoadingButtonWrapper>
                                        ) : (
                                          <button
                                            className='btn btn-primary'
                                            type='button'
                                            onClick={handleRetake}
                                          >
                                            Retake
                                          </button>
                                        )}
                                        <LoadingButtonWrapper isLoading={loadingFaceDetection}>
                                          <Button
                                            id='startExamBtn'
                                            variant='primary'
                                            onClick={() => {
                                              if (
                                                !image ||
                                                isCameraError ||
                                                !faceDetected ||
                                                loadingFaceDetection
                                              ) {
                                                return
                                              }
                                              startExam(false, '')
                                            }}
                                            disabled={
                                              !image ||
                                              isCameraError ||
                                              !faceDetected ||
                                              loadingFaceDetection
                                            }
                                            className='ms-4'
                                          >
                                            Start Now
                                          </Button>
                                        </LoadingButtonWrapper>
                                      </div>
                                    </div>
                                  )}

                                  {!useCamera && (
                                    <Button
                                      variant='primary'
                                      onClick={() => startExam(false, '')}
                                      id='startExamBtn'
                                    >
                                      Start Now
                                    </Button>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                      <div>
                        {!isExamStarted &&
                          (fraudDetected ||
                            (!isNew && statusId === +QuizAnswerStatusEnum.Processed)) && (
                            <div
                              className='card justify-content-center align-items-center'
                              style={{minHeight: 400}}
                            >
                              <div className='d-flex flex-column justify-content-center py-10'>
                                <div className=''>
                                  <Alert variant='danger mb-4 p-10 text-danger'>
                                    <h1 className='text-center mb-6 text-danger'>
                                      {fraudDetected ? 'Fraud Detected' : 'Warning'} !!!
                                    </h1>
                                    <ul className='fs-1'>
                                      <li>Do not exit fullscreen during exam</li>
                                      <li>Do not move to other window during exam</li>
                                      <li>Do not reload page during exam</li>
                                    </ul>
                                  </Alert>
                                </div>

                                {useCamera && (
                                  <div className='mb-10 text-center'>
                                    <CameraCapture
                                      isCaptured={captured}
                                      setImage={setImage}
                                      onError={(err) => handleErrorCamera(err)}
                                      setCanCapture={setCanCapture}
                                      useFaceDetection={useFaceDetection}
                                      setIsCaptured={setCaptured}
                                      setIsFaceDetected={setFaceDetected}
                                      setIsLoadingFaceDetection={setLoadingFaceDetection}
                                      width={200}
                                      height={200}
                                    />
                                    <div className='mt-4'>
                                      {!captured ? (
                                        <LoadingButtonWrapper isLoading={!canCapture}>
                                          <button
                                            className='btn btn-primary'
                                            type='button'
                                            onClick={() => canCapture && setCaptured(true)}
                                            disabled={!canCapture || isCameraError}
                                          >
                                            Take Picture
                                          </button>
                                        </LoadingButtonWrapper>
                                      ) : (
                                        <button
                                          className='btn btn-primary'
                                          type='button'
                                          onClick={handleRetake}
                                        >
                                          Retake
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                )}

                                <div className='d-flex mb-5'>
                                  <input
                                    type='text'
                                    value={fraudNim}
                                    // onFocus={onNimFocus}
                                    onChange={(e) => setFraudNim(e.target.value)}
                                    onKeyPress={onEnterSubmit}
                                    className='form-control me-3 obscure'
                                    placeholder='Please Enter Passcode'
                                  />
                                  <LoadingButtonWrapper
                                    isLoading={useCamera && loadingFaceDetection}
                                  >
                                    <Button
                                      id='resumeExamBtn'
                                      variant='warning'
                                      onClick={() => {
                                        if (
                                          useCamera &&
                                          (!image ||
                                            isCameraError ||
                                            !faceDetected ||
                                            loadingFaceDetection)
                                        ) {
                                          return
                                        }
                                        startExam(true, fraudNim)
                                      }}
                                      // disabled={fraudPassCode ? fraudPassCode !== fraudNim : nim !== fraudNim}
                                      disabled={
                                        isValidateLoading ||
                                        (useCamera &&
                                          (!image ||
                                            isCameraError ||
                                            !faceDetected ||
                                            loadingFaceDetection))
                                      }
                                    >
                                      Resume
                                    </Button>
                                  </LoadingButtonWrapper>
                                </div>

                                {
                                  (isRunning || fraudCount) && (
                                    <div className='d-flex w-auto justify-content-center text-center'>
                                      <Alert variant='danger me-5'>
                                        <span className='fs-9 fw-bold'>Fraud Duration</span>
                                        <div className='d-flex align-items-center w-auto'>
                                          <CountDownTpl
                                            hours={hours}
                                            minutes={minutes}
                                            seconds={seconds}
                                            completed={false}
                                          ></CountDownTpl>
                                        </div>
                                      </Alert>

                                      <Alert variant='danger'>
                                        <span className='fs-9 fw-bold'>Fraud Count</span>
                                        <div className='w-auto fs-1 fw-bold'>{fraudCount}</div>
                                      </Alert>
                                    </div>
                                  )
                                  // <Alert variant='danger mb-10 p-10 text-danger fs-1 text-center'>
                                  //   { isRunning &&
                                  //     <div>Fraud Duration: {hours > 0 ? hours + ' Hours ' : ''}{minutes > 0 ? minutes + ' Minutes ' : ''}{seconds} Seconds</div>
                                  //   }
                                  //   {
                                  //     fraudCount &&
                                  //     <div>Total Fraud: {fraudCount}</div>
                                  //   }
                                  // </Alert>
                                }
                              </div>
                            </div>
                          )}
                      </div>
                    </>
                  )}
                </>
              )}
              <div>
                {(isExamStarted ||
                  statusId > +QuizAnswerStatusEnum.Processed ||
                  isStudent === false ||
                  isExamFinished ||
                  isStrictQuiz === false) && <>{children}</>}
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default QuizWrapper
