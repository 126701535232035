import {addLeadingZero} from '../../classes/quiz/helpers/quiz-helper'

const CountDownTpl = ({days, hours, minutes, seconds, completed}) => {
  if (completed) {
    return <></>
  } else {
    return (
      <>
        {days > 0 && <div className="d-flex align-items-baseline text-danger me-4">
          <div className="fs-1 fw-bolder">{days}</div>
          <div className="fs-8">Days</div>
        </div>}
        <div className='d-flex align-items-baseline text-danger'>
          <div className='fs-1 fw-bolder'>{addLeadingZero(hours)}</div>
          <div className='fs-8'>H</div>
        </div>
        <span className='ms-2'>:</span>
        <div className='d-flex align-items-baseline text-danger'>
          <div className='fs-1 fw-bolder'>{addLeadingZero(minutes)}</div>
          <div className='fs-8'>M</div>
        </div>
        <span className='ms-2'>:</span>
        <div className='d-flex align-items-baseline text-danger'>
          <div className='fs-1 fw-bolder ms-2'>{addLeadingZero(seconds)}</div>
          <div className='fs-8'>S</div>
        </div>
      </>
    )
  }
}

export default CountDownTpl
