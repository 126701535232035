function chunkArray<T>(array: T[], size: number): T[][] {
  const chunkedArray: T[][] = []
  for (let i = 0; i < size; i++) {
    const chunk: T[] = []
    for (let j = i; j < array.length; j += size) {
      chunk.push(array[j])
    }
    chunkedArray.push(chunk)
  }
  return chunkedArray
}

function findItemInArray(array: any[], predicate: (item: any) => boolean): any | null {
  for (const item of array) {
    if (predicate(item)) {
      return item
    }
    if (item.children && item.children.length > 0) {
      const result = findItemInArray(item.children, predicate)
      if (result) {
        return result
      }
    }
  }
  return null
}

function pascalToCamel(str) {
  return str.charAt(0).toLowerCase() + str.slice(1)
}

function keysPascalToCamel(obj) {
  if (Array.isArray(obj)) {
    return obj.map((v) => keysPascalToCamel(v))
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce((result, key) => {
      const camelKey = pascalToCamel(key)
      result[camelKey] = keysPascalToCamel(obj[key])
      return result
    }, {})
  }
  return obj
}

function parseJsonAndPascalToCamel(jsonString) {
  try {
    const parsedObject = JSON.parse(jsonString)
    return keysPascalToCamel(parsedObject)
  } catch (error) {
    console.error('Invalid JSON string provided:', error)
    return null
  }
}

export {chunkArray, findItemInArray, parseJsonAndPascalToCamel}
