import {Route, Routes, Outlet} from 'react-router-dom'
import {QuizExamPage} from './exam/page/QuizExamPage'
import {QuizExam} from './exam/page/QuizExam'
import ValidateTokenSeb from './exam/page/ValidateTokenSeb'
import {Quiz} from '../classes/quiz/Quiz'

const SebExamPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path='/:classId' element={<QuizExamPage />} />
        <Route path='/validate' element={<ValidateTokenSeb />} />
        <Route path='/quiz/:quizId' element={<Quiz />} />
      </Route>
    </Routes>
  )
}

export default SebExamPage
