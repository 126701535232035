export interface IClassQuizAnswer {
  id: number
  duration?: number
  remainingSeconds?: number
  subjectName?: string
  quizName?: string
  statusId?: number
  nim?: string
  studentName?: string
  totalScore?: number
  maxScore?: number
  questions: IClassQuizQuestion[]
  endDate?: Date
  isExam?: boolean
  autoPublish?: boolean
  isPublished?: boolean
  detailScores?: IClassQuizDetailScore[]
  latestAnsweredIndex?: number
  createdAt?: Date
  startDate?: string
  answerDate?: string
  submitDate?: string
  quizDuration?: number
  quizStartDate?: string
  quizEndDate?: string
  seq?: number
  statusName?: string
  encryptKey?: string
  encryptIv?: string
  dataStatusId?: number
  statusReason?: string
  showResetFraud?: boolean
  showIgnoreFraudLimit?: boolean
}

export interface IAutoSubmitQuiz {
  submitted: boolean
  remainingSecond?: number
}

export interface IClassQuizDetailScore {
  typeId: number
  typeName: string
  score: number
  maxScore: number
  correct: number
  wrong: number
  blank: number
}

export interface IClassQuizQuestion {
  id: number
  typeId: number
  seq: number
  questionText: string
  option1?: string
  option2?: string
  option3?: string
  option4?: string
  option5?: string
  option6?: string
  answer?: number
  answerNumber?: number
  answerEssay?: string
  answerId?: number
  questionId?: number
  correctScore?: number
  correctAnswer?: number
  correctAnswerNumber?: number
  correctAnswerEssay?: string
  answerScore?: number
  isCorrect?: boolean
  remainingSeconds?: number
  randomOptions?: number[]
  browserId?: string
  isUnsavedChange?: boolean
  endAnswerAt?: string
}

export const initialClassQuizAnswer: IClassQuizAnswer = {
  id: 0,
  questions: [],
  quizDuration: 0,
  quizStartDate: '',
  quizEndDate: '',
}
export const initialClassQuizQuestion: IClassQuizQuestion = {
  id: 0,
  typeId: 0,
  seq: 0,
  questionText: '',
  answerEssay: '',
  answer: 0,
  answerNumber: 0,
  correctScore: 0,
  answerScore: 0,
  isCorrect: false,
}

export enum QuestionTypeEnum {
  MultipleChoice = 1,
  EssayText = 2,
  EssayNumber = 3,
}
