import React, { Dispatch, FC, MutableRefObject, SetStateAction, useEffect, useRef, useState } from 'react'
import Compressor from 'compressorjs'
import * as faceapi from 'face-api.js'
import {number} from 'yup'
import {showError} from '../utils/toast'

type Props = {
  isCaptured: boolean
  setIsCaptured: Dispatch<SetStateAction<boolean>>
  setImage: Dispatch<SetStateAction<File | Blob | undefined>>
  setCanCapture?: Dispatch<SetStateAction<boolean>>
  useFaceDetection?: boolean
  width?: number
  height?: number
  onError(error: Error): void
  autoTake?: boolean
  setIsFaceDetected?: Dispatch<SetStateAction<boolean>>
  setIsLoadingFaceDetection?: Dispatch<SetStateAction<boolean>>
}

const CameraCapture: FC<Props> = ({ isCaptured = false, autoTake = false, setIsCaptured, setImage, setCanCapture, useFaceDetection, onError, width = 300, height = 300, setIsFaceDetected = null, setIsLoadingFaceDetection = null }) => {
  const videoRef: MutableRefObject<HTMLVideoElement | null> = useRef(null)
  const canvasRef: MutableRefObject<HTMLCanvasElement | null> = useRef(null)
  const overlayRef: MutableRefObject<HTMLCanvasElement | null> = useRef(null)
  const [imageData, setImageData] = useState<string | null>(null)
  const [hidePreview, setHidePreview] = useState(false)
  const imageRef = useRef<HTMLImageElement|null>(null)

  const startCamera = () => {
    setHidePreview(false)
    navigator.mediaDevices
      .getUserMedia({ video: { width, height, aspectRatio: 1 } })
      .then((stream) => {
        if (videoRef.current) {
          videoRef.current.srcObject = stream
        }
      })
      .catch((err) => {
        console.error('Error accessing camera: ', err)
        onError(err)
      })
  }

  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject as MediaStream
      const tracks = stream.getTracks()
      tracks.forEach((track) => track.stop())
      videoRef.current.srcObject = null
    }
  }

  const captureImage = () => {
    if (canvasRef.current && videoRef.current) {
      const context = canvasRef.current.getContext('2d')
      if (context) {
        context.save()
        context.translate(canvasRef.current.width, 0)
        context.scale(-1, 1)
        context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height)
        context.restore()
        canvasRef.current.toBlob((blob) => {
          if (blob) {
            console.log('original size', blob.size)
            new Compressor(blob, {
              quality: 0.6,
              success(compressedResult) {
                console.log('compressed size', compressedResult.size)
                const reader = new FileReader()
                reader.readAsDataURL(compressedResult)
                reader.onloadend = () => {
                  setImageData(reader.result as string)
                  setImage(compressedResult)
                  setHidePreview(true)
                  stopCamera()
                  if (setIsLoadingFaceDetection) {
                    setIsLoadingFaceDetection(true)
                  }
                  setTimeout(() => {
                    detectFaceFromImg()
                  }, 500)
                }
              },
              error(err) {
                console.error('Compression failed:', err)
                stopCamera()
              },
            })
          }
        }, 'image/png')
      }
    }
  }

  const detectFaceFromImg = async () => {
    if(setIsFaceDetected) {
      if (imageRef.current) {
        var detections = await faceapi.detectAllFaces(imageRef.current, new faceapi.SsdMobilenetv1Options())
        const detection = detections.length > 0 ? detections[0] : null

        if (detection) {
          setIsFaceDetected(true)
        } else {
          setIsFaceDetected(false)
          showError('Face not detected, please retake')
        }
      }

      if (setIsLoadingFaceDetection) {
        setIsLoadingFaceDetection(false)
      }
    }
  }

  const detectFace = async () => {
    if (videoRef.current && overlayRef.current) {
      const detections = await faceapi.detectAllFaces(videoRef.current, new faceapi.SsdMobilenetv1Options())
      const detection = detections.length > 0 ? detections[0] : null
      if (detection) {
        if (autoTake) {
          setIsCaptured(true)
        } else {
          if (setCanCapture) {
            setCanCapture(true)
          }

          const {x, y, width: w, height: h} = detection.box

          // const faceAspectRatio = w / h
          // const faceCoverage = (w * h) / (width * height)

          // const isFullFace = faceCoverage > 0.3 // Adjust this threshold based on your needs
          // const isFacingForward = faceAspectRatio > 0.8 && faceAspectRatio < 1.2 // Aspect ratio close to 1 for a forward face

          // if (isFullFace && isFacingForward){


          const context = overlayRef.current.getContext('2d')
          if (context) {
            overlayRef.current.width = width
            overlayRef.current.height = height
            context.clearRect(0, 0, overlayRef.current.width, overlayRef.current.height)
            context.beginPath()
            context.arc(x + w / 2, y + h / 2, Math.min(w, h) / 2, 0, 2 * Math.PI)
            context.lineWidth = 2
            context.strokeStyle = 'blue'
            context.stroke()
          }
          // } else {
          //   if(setCanCapture) {
          //     setCanCapture(false)
          //   }
          //   const context = overlayRef.current.getContext('2d')
          //   if (context) {
          //     context.clearRect(0, 0, overlayRef.current.width, overlayRef.current.height)
          //   }
          // }
        }
      } else {
        if(setCanCapture) {
          setCanCapture(false)
        }
        const context = overlayRef.current.getContext('2d')
        if (context) {
          context.clearRect(0, 0, overlayRef.current.width, overlayRef.current.height)
        }
      }
    }
  }

  useEffect(() => {
    const loadModels = async () => {
      await faceapi.nets.ssdMobilenetv1.loadFromUri('/models')
    }
    loadModels()

    if (!isCaptured) {
      startCamera()
      const interval = setInterval(() => {
        if (useFaceDetection) {
          detectFace()
        } else {
          if(setCanCapture) {
            setCanCapture(true)
          }
        }
      }, 200) // Adjusted interval for stability
      return () => clearInterval(interval)
    } else {
      captureImage()
    }
  }, [isCaptured, useFaceDetection])

  return (
    <div className='container text-center mt-5' style={{width, height}}>
      <div className='mb-3' style={{ position: 'relative', width, height }}>
        {hidePreview && (
          <img
            src={imageData || ''}
            alt='Captured'
            className='rounded-circle'
            width={width}
            height={height}
            ref={imageRef}
          />
        )}
        {!hidePreview && (
          <>
            <video
              ref={videoRef}
              autoPlay
              className='rounded-circle'
              style={{
                width: `${width}px`,
                height: `${height}px`,
                objectFit: 'cover',
                transform: 'scaleX(-1)',
              }}
            ></video>
            <canvas
              ref={overlayRef}
              className='rounded-circle'
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: `${width}px`,
                height: `${height}px`,
                pointerEvents: 'none',
                transform: 'scaleX(-1)',
              }}
            ></canvas>
          </>
        )}
        <canvas ref={canvasRef} width={width} height={height} style={{ display: 'none' }}></canvas>
      </div>
    </div>
  )
}

export default CameraCapture
