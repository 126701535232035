import axios, {AxiosResponse} from 'axios'
import {IAuthExamResponse, IBaseResponse} from '../../../auth'

const API_URL = process.env.REACT_APP_BASE_API_URL
const SEB_URL = `${API_URL}/sebs`

const authByToken = (token: string): Promise<IBaseResponse<IAuthExamResponse>> => {
  return axios
    .post(`${SEB_URL}/auth`, {token})
    .then((d: AxiosResponse<IBaseResponse<IAuthExamResponse>>) => d.data)
}

export {authByToken}
