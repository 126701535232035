import React, {FC, useCallback, useEffect, useState} from 'react'
import {IClassQuiz} from '../../../../models/IClassQuiz'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../../../_metronic/helpers'
import SafeHTMLRenderer from '../../../../components/SafeHtmlRenderer'
import CameraCapture from '../../../../components/CameraCapture'
import {showError} from '../../../../utils/toast'
import {GoogleReCaptcha, GoogleReCaptchaProvider} from 'react-google-recaptcha-v3'
import LoadingButtonWrapper from '../../../../components/LoadingButtonWrapper'
import {generateSebToken} from '../../core/_request'
import {launchSafeExamBrowser} from '../../../../utils/file'
import {logoutAuth} from '../../../auth/core/_requests'
import {useAuth} from '../../../auth'
import isSafeExamBrowser from '../../../../utils/seb'

type Props = {
  show: boolean
  onClose(start: boolean): void
  quiz: IClassQuiz
}

const ClassQuizStartModal: FC<Props> = ({show, quiz, onClose}) => {
  const [data, setData] = useState<IClassQuiz>()
  const [isLoading, setIsLoading] = useState(false)

  const {logout} = useAuth()

  const isUsingSeb = quiz.isUsingSeb && !isSafeExamBrowser()

  useEffect(() => {
    setData(quiz)
  }, [quiz])

  const handleClose = () => {
    onClose(false)
  }

  const handleStart = async () => {
    if (isUsingSeb) {
      setIsLoading(true)

      generateSebToken(quiz.id, quiz.classId)
        .then((configUrl) => {
          launchSafeExamBrowser(configUrl)
        })
        // .then(async () => {
        //   var response = await logoutAuth()
        //   if (response.code === '0000') {
        //     logout()
        //   }
        // })
        .finally(() => {
          // setIsLoading(false)
        })

      return
    }

    onClose(true)
  }

  const handleErrorCamera = (err: Error) => {
    showError(err.message)
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Start Quiz - {data?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='py-4'>
          <div className='d-flex flex-column'>
            <SafeHTMLRenderer htmlString={data?.description || ''} />
            <div className='d-flex align-items-center justify-content-center mt-5'>
              <div className='d-flex align-items-center fw-bold'>
                <KTIcon iconName='question' className='text-body fs-3 me-1' />
                {data?.questionCount} Questions
              </div>
              <div className='ms-5'>
                <div className='d-flex align-items-center fw-bold'>
                  <KTIcon iconName='timer' className='text-body fs-3 me-1' />
                  {data?.duration} Minutes
                </div>
              </div>
            </div>
            <div className='mt-7 text-center'>
              <LoadingButtonWrapper isLoading={isLoading}>
                <button type='button' className='btn btn-primary' onClick={handleStart} disabled={isLoading}>
                  {isUsingSeb ? 'Launch' : 'Start Now'}
                </button>
              </LoadingButtonWrapper>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export {ClassQuizStartModal}
