import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

function ConfirmEndAssess({ show, handleClose, handleConfirm, isLoading = false, error = null }) {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>End Assessment?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error ? (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{error}</div>
          </div>
        ) : (
          ''
        )}
        <div>Are you sure want to end the quiz assessment?</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={isLoading}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleConfirm} disabled={isLoading}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export { ConfirmEndAssess }