import clsx from 'clsx'

const QuizEssayNumber = ({
  isReadOnly,
  showedQuestion,
  numberRef,
  isLoading,
  handleChangeNumber,
}) => {
  return (
    <div key={'number'} className='form-group w-200px mt-5'>
      <input
        className={clsx(
          'form-control',
          {
            'is-invalid':
              isReadOnly && showedQuestion.answerNumber !== showedQuestion.correctAnswerNumber,
          },
          {
            'is-valid':
              isReadOnly && showedQuestion.answerNumber === showedQuestion.correctAnswerNumber,
          }
        )}
        type='number'
        id={'question-' + showedQuestion.id}
        ref={numberRef}
        value={showedQuestion.answerNumber || 0}
        readOnly={isReadOnly}
        disabled={isLoading}
        onChange={() => {
          if (!isReadOnly) {
            handleChangeNumber()
          }
        }}
      />
      {isReadOnly && (
        <div className='p-3 bg-success rounded-1 text-white mt-2'>
          Correct Answer: <strong>{showedQuestion.correctAnswerNumber}</strong>
        </div>
      )}
    </div>
  )
}

export default QuizEssayNumber
