import {useEffect, useState} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {HeaderWrapper} from './components/header'
import {ScrollTop} from './components/scroll-top'
import {Content} from './components/content'
import {FooterWrapper} from './components/footer'
import {Sidebar} from './components/sidebar'
import {PageDataProvider} from './core'
import {reInitMenu} from '../helpers'
import {ToolbarWrapper} from './components/toolbar'
import {getAuth, setAuth, useAuth} from '../../app/modules/auth'
import {getMenu, getUserByToken} from '../../app/modules/auth/core/_requests'
import Connector from '../helpers/signalr-connection'

const MasterLayout = () => {
  const location = useLocation()
  const {setCurrentMenu, currentUser, logout} = useAuth()
  const connector = Connector()

  useEffect(() => {
    getMenu().then((menu) => {
      if (menu) {
        setCurrentMenu(menu.data.data)
      }
    })
  }, [])

  useEffect(() => {
    reInitMenu()
  }, [location.key])

  useEffect(() => {
    reInitMenu()
  }, [location.key])

  const [intervalId, setIntervalId] = useState<NodeJS.Timer | null>(null) // Variable to hold the interval ID

  useEffect(() => {
    const validateFunc = async () => {
      try {
        console.log('Validating ...')
        const {data} = await getUserByToken(getAuth()?.api_token ?? '')
        setAuth({api_token: data.data?.token})
      } catch (e) {
        console.error(e)
      }
    }

    if (!intervalId) {
      validateFunc()
      const id = setInterval(validateFunc, 10 * 60 * 1000)
      setIntervalId(id)
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [intervalId])

  useEffect(() => {
    if (currentUser?.id) {
      connector.events(`ping_${currentUser.id}`, (user, message) => {
        connector.newMessage(`pong_${currentUser.id}`, message)
      })

      // connector.events(`logout_${currentUser.id}`, (user, message) => {
      //   logout()
      // })
    }
  }, [currentUser])

  return (
    <PageDataProvider>
      <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
        <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
          <HeaderWrapper />
          <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
            <Sidebar />
            <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
              <div className='d-flex flex-column flex-column-fluid'>
                <ToolbarWrapper />
                <Content>
                  <Outlet />
                </Content>
              </div>
              <FooterWrapper />
            </div>
          </div>
        </div>
      </div>
      <ScrollTop />
    </PageDataProvider>
  )
}

export {MasterLayout}
