import {PageLink} from '../../../../../_metronic/layout/core'
import {QuestionTypeEnum} from '../../../../models/IClassQuizAnswer'
import {decryptFromBase64, encryptToBase64} from '../../../../utils/encryption'
import {getSavedState, setSavedState} from '../../../../utils/saved-state'

export const courseBreadcrumbs: Array<PageLink> = [
  {
    title: 'Classes',
    path: '/classes',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Class Detail',
    path: `/classes/`,
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const addLeadingZero = (value) => {
  if (value < 0) return '00'
  if (value > 9) return value.toString()
  return '0' + value
}

export const isQuizInfoHandledError = (e: any) => {
  return (
    e.message === "Quiz hasn't started yet" ||
    e.message.includes('taken by someone else') ||
    e.message.includes('deadline has passed') ||
    e.message.includes('committing fraud')
  )
}

export const isQuestionAnswerCorrectByType = (i: number, question) => {
  return question.isCorrect
  //   if (question.typeId === +QuestionTypeEnum.MultipleChoice) {
  //   return question.answer === question.correctAnswer
  // } else if (question.typeId === +QuestionTypeEnum.EssayNumber) {
  //   return question.answerNumber === question.correctAnswerNumber
  // } else if (question.typeId === +QuestionTypeEnum.EssayText) {
  //   return question.isCorrect
  // }
}

export const isQuestionAnswerWrongByType = (i: number, question) => {
  return !question.isCorrect
  //   if (question.typeId === +QuestionTypeEnum.MultipleChoice) {
  //   return question.answer !== question.correctAnswer
  // } else if (question.typeId === +QuestionTypeEnum.EssayNumber) {
  //   return question.answerNumber !== question.correctAnswerNumber
  // } else if (question.typeId === +QuestionTypeEnum.EssayText) {
  //   return question.isCorrect === false
  // }
}

export const isQuestionAnswerNotAnswered = (question) => {
  return (
    !question.answer &&
    !question.answerEssay &&
    !question.answerNumber &&
    question.answerScore !== 0
  )
}

export const getTypeName = (typeId: number) => {
  switch (typeId) {
    case +QuestionTypeEnum.MultipleChoice:
      return 'Multiple Choice'
    case +QuestionTypeEnum.EssayText:
      return 'Essay Text'
    case QuestionTypeEnum.EssayNumber:
      return 'Essay Number'
  }

  return ''
}

export const getOptionQuestionSwitch = (q: number, showedQuestion) => {
  switch (q) {
    case 1:
      return showedQuestion.option1
    case 2:
      return showedQuestion.option2
    case 3:
      return showedQuestion.option3
    case 4:
      return showedQuestion.option4
    case 5:
      return showedQuestion.option5
    case 6:
      return showedQuestion.option6
    default:
      return undefined
  }
}

interface ISavedAnswer {
  questionId: number
  answerEssay: string
}

const getSavedKey = (userId, quizId) => {
  const key = encryptToBase64(JSON.stringify({userId, quizId}))
  return `svdq-${key}`
}

export const getSavedQuizAnswer = (userId, quizId) => {
  try {
    const saved = getSavedState(getSavedKey(userId, quizId))
    if (saved) {
      return JSON.parse(decryptFromBase64(saved))
    }
    return null
  } catch {
    return null
  }
}

export const getSavedQuizAnswerByQuestionId = (userId, quizId, questionId) => {
  if (!quizId) {
    return null
  }
  const saved = getSavedQuizAnswer(userId, quizId) || []
  if (saved) {
    const selectedQuestion = saved.find((f) => f.questionId === questionId)
    if (selectedQuestion) {
      return selectedQuestion
    }
  }

  return null
}

export const saveQuizAnswer = (userId, quizId, value) => {
  if (!quizId) {
    return
  }
  const saved = getSavedQuizAnswer(userId, quizId) || []
  const selectedQuestion = saved.find((f) => f.questionId === value.questionId)

  if (selectedQuestion) {
    selectedQuestion.lastUpdated = value.lastUpdated
    selectedQuestion.answerEssay = value.answerEssay
    selectedQuestion.answer = value.answer
    selectedQuestion.answerNumber = value.answerNumber
  } else {
    saved.push(value)
  }

  setSavedState(getSavedKey(userId, quizId), encryptToBase64(JSON.stringify(saved)))
}

export const removeQuizAnswerQuestion = (userId, quizId, questionId) => {
  if (!quizId) {
    return
  }
  const saved = getSavedQuizAnswer(userId, quizId) || []
  const selectedQuestionIdx = saved.findIndex((f) => f.questionId === questionId)
  if (selectedQuestionIdx >= 0) {
    saved.splice(selectedQuestionIdx, 1)
    setSavedState(getSavedKey(userId, quizId), saved)
  }
}
