import clsx from 'clsx'
import {getOptionQuestionSwitch} from '../helpers/quiz-helper'
import SafeHTMLRenderer from '../../../../components/SafeHtmlRenderer'

const QuizMultipleChoice = ({
  q,
  showedQuestion,
  isReadOnly,
  data,
  isStudent,
  isLoading,
  changeOption,
}) => {
  return (
    getOptionQuestionSwitch(q, showedQuestion) && (
      <div
        key={`choice-${q}`}
        className={clsx(
          'form-check form-check-custom form-check-solid mt-3 p-2 rounded-1',
          {
            'bg-success':
              isReadOnly && showedQuestion.correctAnswer === q && (!data.isExam || !isStudent),
          },
          {
            'bg-danger':
              isReadOnly &&
              showedQuestion.correctAnswer !== q &&
              showedQuestion.answer === q &&
              (!data.isExam || !isStudent),
          }
        )}
      >
        <input
          className='form-check-input'
          type='radio'
          checked={showedQuestion.answer === q}
          id={'question-' + showedQuestion.id + '-' + q}
          disabled={isLoading}
          onChange={() => {
            if (!isReadOnly) {
              changeOption(q)
            }
          }}
        />
        <label
          className={clsx('form-check-label fw-bold user-select-none', {
            'text-white':
              isReadOnly &&
              (showedQuestion.correctAnswer === q || showedQuestion.answer === q) &&
              (!data.isExam || !isStudent),
          })}
          htmlFor={'question-' + showedQuestion.id + '-' + q}
        >
          <SafeHTMLRenderer
            className='question'
            htmlString={getOptionQuestionSwitch(q, showedQuestion)}
          />
        </label>
      </div>
    )
  )
}

export default QuizMultipleChoice
