import {KTIcon} from '../../../../../_metronic/helpers'
import {QuizAnswerStatusEnum} from '../../../../models/IClassQuiz'
import {QuestionTypeEnum} from '../../../../models/IClassQuizAnswer'
import isSafeExamBrowser from '../../../../utils/seb'
import {useAuth} from '../../../auth'
import {logoutSeb} from '../../../auth/core/_requests'
import {getTypeName} from '../helpers/quiz-helper'

const QuizSummaryResult = ({data, handlePrevClick, handleNextClick, isLoading}) => {
  const {currentUser} = useAuth()
  const handleLogOut = async () => {
    var response = await logoutSeb()
    if (response.code === '0000') {
      window.location.href = 'https://google.com'
    }
  }

  return (
    <div className='card-body pt-9 pb-9 d-flex flex-column justify-content-between'>
      <div className='d-flex flex-column'>
        <div className='fs-4 fw-bold flex-row-fluid mb-2'>Summary</div>
        {data.detailScores?.map((score, j) => {
          return (
            <div key={j} className='my-2 form-control'>
              <div className='fw-bold fs-5'>{getTypeName(score.typeId)}</div>
              <hr className='line-h' />
              <div className='fw-bold'>
                Score:{' '}
                {score.typeId === +QuestionTypeEnum.EssayText &&
                data.statusId === +QuizAnswerStatusEnum.NeedCalculate
                  ? 'Waiting Assessment'
                  : `${score.score} / ${score.maxScore}`}
              </div>
              {(score.typeId !== +QuestionTypeEnum.EssayText ||
                data.statusId !== +QuizAnswerStatusEnum.NeedCalculate) && (
                <>
                  <div>Correct Answer: {score.correct}</div>
                  <div>Wrong Answer: {score.wrong}</div>
                  <div>Not Answered: {score.blank}</div>
                </>
              )}
            </div>
          )
        })}
      </div>
      <div className='mt-9 d-flex justify-content-between'>
        {isSafeExamBrowser() && currentUser && (
          <button
            type='button'
            onClick={handleLogOut}
            className='btn btn-danger'
            disabled={isLoading}
          >
            Exit Quiz <KTIcon iconName='exit' className='fs-3' />
          </button>
        )}
        <button type='button' onClick={handlePrevClick} className='invisible' disabled={true}>
          <KTIcon iconName='arrow-left' className='fs-3' /> Previous
        </button>
        {(data.statusId === +QuizAnswerStatusEnum.Processed || data.isPublished) && (
          <button
            type='button'
            onClick={handleNextClick}
            className='btn btn-warning'
            disabled={isLoading}
          >
            Next <KTIcon iconName='arrow-right' className='fs-3' />
          </button>
        )}
      </div>
    </div>
  )
}

export default QuizSummaryResult
