import {KTIcon} from '../../../../../_metronic/helpers'

const HeaderQuizInfo = ({quizName, nim, studentName, subjectName}) => {
  return (
    <div className='col-8 d-flex flex-column'>
      <h3>{quizName}</h3>
      <div className='d-flex flex-column flex-md-row align-items-md-center align-items-start'>
        <div className='fw-semibold fs-6 text-muted'>
          <KTIcon iconName='credit-cart' className='fs-6' /> {nim}
        </div>
        <div className='fw-semibold fs-6 ms-md-2 ms-0 mt-md-0 mt-2 text-muted'>
          <KTIcon iconName='user-square' className='fs-6' /> {studentName}
        </div>
        <div className='fw-semibold fs-6 ms-md-2 ms-0 mt-md-0 mt-2 text-muted'>
          <KTIcon iconName='book' className='fs-6' /> {subjectName}
        </div>
      </div>
    </div>
  )
}

export default HeaderQuizInfo
