import clsx from 'clsx'
import {KTIcon} from '../../../../../_metronic/helpers'
import {useEffect, useState} from 'react'
import {QuestionTypeEnum} from '../../../../models/IClassQuizAnswer'

const QuizNavigationButton = ({
  handleNextClick,
  handlePrevClick,
  showedIndex,
  data,
  isReadOnly,
  isStudent,
  isLoading,
  questions,
  isPrevButtonDisabled,
  showedQuestion,
}) => {
  const [disableInline, setDisableInline] = useState(false)

  // useEffect(() => {
  //   setDisableInline(true)

  //   setTimeout(() => {
  //     setDisableInline(false)
  //   }, 1000)
  // }, [showedIndex])

  useEffect(() => {
    if (!isReadOnly && isPrevButtonDisabled) {
      if (showedQuestion.typeId === +QuestionTypeEnum.MultipleChoice) {
        setDisableInline(showedQuestion.answer === null)
      }
    }
  }, [isPrevButtonDisabled, showedQuestion])

  return (
    <div className='mt-9 d-flex justify-content-between'>
      <div>
        <button
          type='button'
          onClick={handlePrevClick}
          className={clsx('btn btn-warning', {
            invisible: showedIndex === (data.isExam && isReadOnly && isStudent ? -1 : 0),
            'd-none': isPrevButtonDisabled && !isReadOnly,
          })}
          disabled={showedIndex === (data.isExam && isReadOnly && isStudent ? -1 : 0) || isLoading}
        >
          <KTIcon iconName='arrow-left' className='fs-3' /> Previous
        </button>
      </div>

      <div>
        <button
          type='button'
          onClick={handleNextClick}
          className={clsx('btn btn-warning', {
            'd-none': showedIndex === questions.length - 1,
          })}
          disabled={showedIndex === questions.length - 1 || isLoading || disableInline}
        >
          Next <KTIcon iconName='arrow-right' className='fs-3' />
        </button>
      </div>
    </div>
  )
}

export default QuizNavigationButton
