import {FC, useEffect, useState} from 'react'
import {KTIcon, QUERIES} from '../../../../../_metronic/helpers'
import {IClassQuiz, QuizAnswerStatusEnum} from '../../../../models/IClassQuiz'
import moment from 'moment'
import clsx from 'clsx'
import {OverlayTooltip} from '../../../../components/OverlayTooltip'
import {useNavigate, useParams} from 'react-router-dom'
import {useQuery} from 'react-query'
import {showError} from '../../../../utils/toast'
import {getQuizByClassId} from '../../../classes/core/_request'
import {ClassesLoading} from '../../../classes/components/ClassesLoading'
import {ClassQuizStartModal} from '../../../classes/class-detail/components/ClassQuizStartModal'
import isSafeExamBrowser from '../../../../utils/seb'

type Props = {}

const QuizExamPage: FC<Props> = () => {
  const {classId} = useParams()
  const id = Number(classId) ?? 0
  const [selectedQuiz, setSelectedQuiz] = useState<IClassQuiz>()
  const [showStartModal, setShowStartModal] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    if (!isSafeExamBrowser()) {
      navigate('/')
    }
  }, [window.location])

  const handleStartQuiz = (item: IClassQuiz) => {
    if (item.answerStatusId === +QuizAnswerStatusEnum.Processed) {
      handleGoToQuiz(item)
    } else {
      setSelectedQuiz(item)
      setShowStartModal(true)
    }
  }

  const handleGoToQuiz = (item: IClassQuiz) => {
    navigate(`/safe-exam-browser/quiz/${item.id}?cid=${id}`)
  }

  const handleModalClose = (start: boolean) => {
    if (start) {
      const quizId = selectedQuiz?.id
      navigate(`/safe-exam-browser/quiz/${quizId}?cid=${id}`)
    }
    setSelectedQuiz(undefined)
    setShowStartModal(false)
  }

  const {
    data,
    isFetching: isLoading,
    refetch: refresh,
  } = useQuery(
    [QUERIES.CLASS_QUIZZES, id],
    async () => {
      try {
        const result = await getQuizByClassId(id)

        if (result.code === '0000') {
          return result.data
        } else {
          showError(result.message || 'Something went wrong')
          return []
        }
      } catch (e: any) {
        console.error(e)
        showError(e.message || 'Something went wrong')
        return []
      }
    },
    {initialData: [], enabled: id > 0}
  )

  return (
    <>
      <div className='card' style={{minHeight: 'calc(100vh - 426px)'}}>
        <div className='card-body'>
          <div className='table-header text-end'>
            <button
              className='btn btn-outline btn-outline-warning'
              type='button'
              onClick={() => refresh()}
            >
              <i className='fas fa-refresh'></i> Refresh
            </button>
          </div>
          <div className='table-responsive'>
            <table className='table table-striped table-rounded dataTable align-middle table-row-dashed no-footer gs-7 fs-6 gy-5'>
              <thead>
                <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                  <th style={{minWidth: '125px'}}>Title</th>
                  <th style={{minWidth: '100px'}}>Schedule</th>
                  <th style={{minWidth: '78px'}}>Duration</th>
                  <th style={{minWidth: '100px'}}>Start</th>
                  <th style={{minWidth: '100px'}}>Submit Date</th>
                  <th className={'text-center'} style={{minWidth: '78px'}}>
                    Status
                  </th>
                  <th className={'text-center'} style={{minWidth: '78px'}}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-bold'>
                {data?.map((item, i) => (
                  <tr key={i}>
                    <td className='fw-semibold'>{item.name}</td>
                    <td>{moment(item.startDate).format('DD MMM YYYY HH:mm')}</td>
                    <td>{item.duration} Minutes</td>
                    <td>
                      {item.answerDate && moment(item.answerDate).format('DD MMM YYYY HH:mm')}
                    </td>
                    <td>
                      {item.submitDate && moment(item.submitDate).format('DD MMM YYYY HH:mm')}
                    </td>
                    <td className='text-center'>
                      <OverlayTooltip
                        title={
                          !item.answerId
                            ? 'Not Started'
                            : item.answerStatusId === +QuizAnswerStatusEnum.NeedCalculate
                            ? 'Waiting to Check'
                            : item.answerStatusId === +QuizAnswerStatusEnum.Processed
                            ? 'In Progress'
                            : 'Finished'
                        }
                      >
                        <span>
                          <KTIcon
                            iconName={
                              !item.answerId ||
                              item.answerStatusId === +QuizAnswerStatusEnum.NeedCalculate
                                ? 'timer'
                                : item.answerStatusId === +QuizAnswerStatusEnum.Processed
                                ? 'double-right-arrow'
                                : 'check-square'
                            }
                            className={clsx(
                              'fs-1',
                              {
                                'text-success':
                                  item.answerStatusId === +QuizAnswerStatusEnum.Completed,
                              },
                              {
                                'text-warning':
                                  item.answerStatusId === +QuizAnswerStatusEnum.NeedCalculate ||
                                  item.answerStatusId === +QuizAnswerStatusEnum.Processed,
                              },
                              {'text-danger': !item.answerId}
                            )}
                          />
                        </span>
                      </OverlayTooltip>
                    </td>
                    <td className='text-center'>
                      {!item.answerId && item.isCanStart && (
                        <button
                          type='button'
                          onClick={() => handleStartQuiz(item)}
                          className='btn btn-text-primary p-0'
                        >
                          Start Quiz
                        </button>
                      )}

                      {item.answerId &&
                        item.isCanStart &&
                        item.answerStatusId === +QuizAnswerStatusEnum.Processed && (
                          <button
                            type='button'
                            onClick={() => handleGoToQuiz(item)}
                            className='btn btn-active-text-secondary btn-text-primary p-0'
                          >
                            Resume
                          </button>
                        )}

                      {item.answerId &&
                        (item.answerStatusId === +QuizAnswerStatusEnum.Completed ||
                          item.answerStatusId === +QuizAnswerStatusEnum.NeedCalculate) && (
                          <button
                            type='button'
                            onClick={() => handleGoToQuiz(item)}
                            className='btn btn-active-text-secondary btn-text-primary p-0'
                          >
                            Result
                          </button>
                        )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {isLoading && <ClassesLoading />}
        {selectedQuiz && (
          <ClassQuizStartModal
            show={showStartModal}
            onClose={(start) => handleModalClose(start)}
            quiz={selectedQuiz}
          />
        )}
      </div>
    </>
  )
}

export {QuizExamPage}
