import clsx from 'clsx'
import {QuizAnswerStatusEnum} from '../../../../models/IClassQuiz'
import {
  isQuestionAnswerCorrectByType,
  isQuestionAnswerNotAnswered,
  isQuestionAnswerWrongByType,
} from '../helpers/quiz-helper'
import {useEffect} from 'react'

const QuizNavigationGrid = ({
  data,
  isReadOnly,
  isStudent,
  changeIndex,
  isLoading,
  showedIndex,
  questions,
  isAssess,
  handleShowConfirm,
  handleShowConfirmAssess,
  isPrevButtonDisabled,
}) => {
  const isCorrectAnswer = (i: number) => {
    const question = questions[i]
    if (data.statusId !== +QuizAnswerStatusEnum.Processed) {
      return isQuestionAnswerCorrectByType(i, question)
    }
    return false
  }

  const isNotAnswered = (i: number) => {
    const question = questions[i]
    if (data.statusId !== +QuizAnswerStatusEnum.Processed) {
      return true
    }
    return isQuestionAnswerNotAnswered(question)
  }

  const isWrongAnswer = (i: number) => {
    const question = questions[i]
    if (data.statusId !== +QuizAnswerStatusEnum.Processed) {
      return isQuestionAnswerWrongByType(i, question)
    }
    return false
  }

  return (
    <div className='card quiz-navigation-grid'>
      <div className='card-body pt-9 pb-9 d-flex flex-column justify-content-between'>
        <div className='d-flex flex-wrap'>
          {data.isExam && isReadOnly && isStudent && (
            <button
              type='button'
              onClick={() => changeIndex(-1)}
              disabled={isLoading}
              className={clsx(
                'btn m-1 w-55px px-0 position-relative',
                {
                  'btn-warning': showedIndex === -1,
                },
                {'btn-light': showedIndex !== -1},
                {
                  active: showedIndex === -1,
                }
              )}
            >
              S
            </button>
          )}
          {questions.map((question, i) => (
            <div key={i}>
              <button
                type='button'
                onClick={() => changeIndex(i)}
                disabled={isLoading}
                className={clsx(
                  'btn m-1 w-55px px-0 position-relative',
                  {
                    'btn-warning': showedIndex === i,
                  },
                  {'btn-light': showedIndex !== i},
                  {
                    'btn-success':
                      showedIndex !== i &&
                      ((isCorrectAnswer(i) && (!data.isExam || !isStudent)) || !isNotAnswered(i)),
                  },
                  {
                    'btn-danger':
                      showedIndex !== i && isWrongAnswer(i) && (!data.isExam || !isStudent),
                  },
                  {
                    active:
                      (isCorrectAnswer(i) || isWrongAnswer(i)) &&
                      showedIndex === i &&
                      (!data.isExam || !isStudent),
                  },
                  {
                    'btn-readonly': isPrevButtonDisabled && !isReadOnly,
                  }
                )}
              >
                {i + 1} {isPrevButtonDisabled}
              </button>
            </div>
          ))}
        </div>

        {(!isReadOnly || isAssess) && (
          <div className={clsx('mt-15')}>
            <button
              type='button'
              onClick={!isAssess ? handleShowConfirm : handleShowConfirmAssess}
              className='btn btn-warning w-100'
              disabled={isLoading}
            >
              Submit{isAssess && ' Assessment'}
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default QuizNavigationGrid
