// CustomSelect.js
import React, {FC, useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import Select from 'react-select'
import axios from 'axios'
import clsx from 'clsx'
import {stringifyRequestQuery, useDebounce} from '../../../../_metronic/helpers'
import {getSatkerLov} from '../core/_requests'

interface Props {
  field: any
  form: any
  className?: string
  defaultOption?: any
  initialOptions?: any[]
  disabled?: boolean
  isClearable?: boolean
}

const SatkerSelect: FC<Props> = ({
  field,
  form,
  disabled = false,
  isClearable = false,
  className = '',
  defaultOption,
  initialOptions = [],
  ...rest
}) => {
  const {setFieldValue} = form
  const [keyword, setKeyword] = useState<string | undefined>(undefined)
  const debouncedSearchTerm = useDebounce(keyword, 150)

  const {isLoading, data, refetch} = useQuery(
    'satker-select',
    async () => {
      try {
        const response = await getSatkerLov(
          stringifyRequestQuery({
            keyword: debouncedSearchTerm,
            pageSize: 30,
          })
        )
        return response.data?.records
      } catch (ex) {
        console.log(ex)
      }
    },
    {enabled: false}
  )

  const handleChange = (selectedOption) => {
    setFieldValue(field.name, selectedOption.value)
  }

  const options =
    keyword === undefined
      ? initialOptions
      : data?.some((item) => item.id !== null)
      ? data?.map((option) => ({
          value: option.id,
          label: option.alias,
        }))
      : []

  const handleInputChange = (value) => {
    setKeyword(value)
  }

  useEffect(() => {
    if (debouncedSearchTerm === undefined) return
    refetch()
  }, [debouncedSearchTerm])

  return (
    <Select
      {...rest}
      name={field.name}
      isLoading={isLoading}
      options={options}
      onChange={handleChange}
      value={options.find((option) => option.value === field.value)} // Set the initial selected value
      classNamePrefix='react-select'
      className={clsx(
        'react-select',
        className,
        {'is-invalid': form.touched[field.name] && form.errors[field.name]},
        {'is-valid': form.touched[field.name] && !form.errors[field.name]}
      )}
      isDisabled={disabled}
      onInputChange={handleInputChange}
      isClearable={isClearable}
      onMenuOpen={() => handleInputChange('')}
      styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
      menuPortalTarget={document.body}
      menuShouldScrollIntoView={false}
      placeholder="Type to search..."
    />
  )
}

export default SatkerSelect
