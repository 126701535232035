import {Alert} from 'react-bootstrap'

const QuizAlertOS = ({allowedOs}) => {
  return (
    <div className='card justify-content-center align-items-center' style={{minHeight: 400}}>
      <div className='d-flex justify-content-center'>
        <div className='d-flex flex-column justify-content-center'>
          <Alert variant='danger mb-10 p-10 text-danger'>
            <h1 className='text-center mb-6 text-danger'>
              This Operating System is Not Allowed !!!
            </h1>
            <p className='fs-2'>Please use one of these operating system: </p>
            <ul className='fs-2'>
              {allowedOs.map((m, index) => (
                <li key={index}>{m}</li>
              ))}
            </ul>
          </Alert>
        </div>
      </div>
    </div>
  )
}

export default QuizAlertOS
