import {PageTitle} from '../../../../_metronic/layout/core'
import {useLocation, useParams} from 'react-router-dom'
import {useEffect, useRef, useState} from 'react'
import {
  IClassQuizAnswer,
  IClassQuizQuestion,
  initialClassQuizAnswer,
  initialClassQuizQuestion,
  QuestionTypeEnum,
} from '../../../models/IClassQuizAnswer'
import {
  answerQuiz,
  autoEndQuiz,
  correctionQuiz,
  endCorrectionQuiz,
  endQuiz,
  getQuiz,
  getQuizByAnswerId,
  getRemaining,
  quizInfo,
  startQuestionAnswer,
  startQuiz,
  startQuizWithPicture,
} from '../core/_request'
import {ClassesLoading} from '../components/ClassesLoading'
import {ConfirmEndQuiz} from './components/ConfirmEndQuiz'
import {useAuth} from '../../auth'
import RoleEnum from '../../../enums/RoleEnum'
import {QuizAnswerStatusEnum} from '../../../models/IClassQuiz'
import {ConfirmEndAssess} from './components/ConfirmEndAssess'
import QuizWrapper from './QuizWrapper'
import {showError, showInfo} from '../../../utils/toast'
import getFingerprint from '../../../utils/fingerprint'
import {encryptAES} from '../../../utils/encryption'
import {
  courseBreadcrumbs,
  getSavedQuizAnswerByQuestionId,
  isQuizInfoHandledError,
  removeQuizAnswerQuestion,
  saveQuizAnswer,
} from './helpers/quiz-helper'
import QuizHeader from './components/QuizHeader'
import QuizSummaryResult from './components/QuizSummaryResult'
import QuizQuestion from './components/QuizQuestion'
import QuizMultipleChoice from './components/QuizMultipleChoice'
import QuizEssayNumber from './components/QuizEssayNumber'
import QuizEssayText from './components/QuizEssayText'
import QuizNavigationButton from './components/QuizNavigationButton'
import QuizNavigationGrid from './components/QuizNavigationGrid'
import BlockLoading from '../../../components/BlockLoading'
import {relative} from 'path'
import useLocalStorage from 'react-query/types/devtools/useLocalStorage'

interface ITimerPerquestion {
  timer: number | null
  id: number | null
}

const Quiz = () => {
  let {quizId} = useParams()
  let location = useLocation()
  const {currentUser} = useAuth()
  const [data, setData] = useState<IClassQuizAnswer>({...initialClassQuizAnswer})
  const [questions, setQuestions] = useState<IClassQuizQuestion[]>([])
  const [loaded, setLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [autoSubmitLoading, setAutoSubmitLoading] = useState(false)
  const [breadCrumbs, setBreadCrumbs] = useState(courseBreadcrumbs)
  const [showedIndex, setShowedIndex] = useState<number>()
  const [showedQuestion, setShowedQuestion] = useState<IClassQuizQuestion>({
    ...initialClassQuizQuestion,
  })
  const numberRef = useRef<HTMLInputElement>(null)
  const scoreRef = useRef<HTMLInputElement>(null)
  const textRef = useRef<HTMLTextAreaElement>(null)
  const correctRef = useRef<HTMLInputElement>(null)
  const [countdown, setCountdown] = useState(0)
  const [isReadOnly, setIsReadOnly] = useState(true)
  const [showConfirm, setShowConfirm] = useState(false)
  const [showConfirmAssess, setShowConfirmAssess] = useState(false)
  const [isAssess, setIsAssess] = useState(false)
  const [isStudent, setIsStudent] = useState<boolean | null>(null)
  const [error, setError] = useState('')
  const [infiniteTime, setInfiniteTime] = useState(false)
  const [dirty, setDirty] = useState(false)
  const [isExamFinished, setIsExamFinished] = useState(false)
  const [countdownKey, setCountdownKey] = useState('')
  const [encryptKey, setEncryptKey] = useState('')
  const [encryptIv, setEncryptIv] = useState('')
  const [quizHeader, setQuizHeader] = useState<any | null>()
  const [syncRemainingTimerId, setSyncRemainingTimerId] = useState<NodeJS.Timer | null>(null)
  const [remainingSecondsPerQuestion, setRemainingSecondsPerQuestion] = useState<ITimerPerquestion>(
    {
      timer: null,
      id: null,
    }
  )
  const [loadingSaveDraft, setLoadingSaveDraft] = useState(false)
  const [isAfterFraud, setIsAfterFraud] = useState(false)

  const handleUserRole = () => {
    if (currentUser?.roleId === +RoleEnum.Student) {
      setIsStudent(true)
    } else {
      setIsStudent(false)
    }
  }

  const getQuizInfo = () => {
    quizInfo(quizId)
      .then((response) => {
        if (response) {
          console.log('header', response)

          setQuizHeader(response)
          if (response?.lastFraudStartDate) {
            setIsAfterFraud(true)
          }

          if (
            (response?.statusId ?? 0) > +QuizAnswerStatusEnum.Processed &&
            currentUser?.roleId === +RoleEnum.Student
          ) {
            getDataByQuizId()
          }
        }
      })
      .catch((e) => {
        setIsLoading(false)
        if (isQuizInfoHandledError(e)) {
          setIsExamFinished(true)
          setError(e.message)
        }
      })
  }

  useEffect(() => {
    handleUserRole()
    getQuizInfo()
  }, [quizId, currentUser])

  const handleStartSuccess = (response) => {
    setIsReadOnly(false)
    setData(response)
    setLoaded(true)
    setEncryptKey(response.encryptKey || '')
    setEncryptIv(response.encryptIv || '')
  }

  const handleStartFailed = (e) => {
    if (isQuizInfoHandledError(e)) {
      setIsExamFinished(true)
      setError(e.message)
    } else {
      getDataByQuizId()
    }
  }

  const startHandler = (lastQuestionId: number | null = null) => {
    if (currentUser?.roleId === +RoleEnum.Student) {
      setIsStudent(true)
      if (!data.id) {
        setIsLoading(true)
        startQuiz(quizId, currentUser.id, lastQuestionId)
          .then((response) => {
            if (response) {
              handleStartSuccess(response)
            }
          })
          .catch((e) => {
            handleStartFailed(e)
          })
          .finally(() => {
            setIsLoading(false)
          })
      } else {
        //finish fraud
        if (showedQuestion?.id) {
          setIsAfterFraud(true)
          console.log('startHandler')

          startQuestion(showedQuestion?.id, true)
        }
      }
    } else {
      setIsStudent(false)
      getDataByAnswerId()
    }
  }

  const startHandlerWithPicture = (image: File | Blob) => {
    if (currentUser?.roleId === +RoleEnum.Student) {
      setIsStudent(true)
      if (!data.id) {
        setIsLoading(true)
        startQuizWithPicture(quizId, image, currentUser.id)
          .then((response) => {
            if (response) {
              handleStartSuccess(response)
            }
          })
          .catch((e) => {
            handleStartFailed(e)
          })
          .finally(() => {
            setIsLoading(false)
          })
      }
    } else {
      setIsStudent(false)
      getDataByAnswerId()
    }
  }

  useEffect(() => {
    let q = data.questions

    q.forEach((e) => {
      if (isReadOnly) return
      const savedAnswer = getSavedQuizAnswerByQuestionId(currentUser?.id, data?.id, e.id)
      if (
        savedAnswer &&
        (!e.endAnswerAt || new Date(savedAnswer.lastUpdated) >= new Date(e.endAnswerAt))
      ) {
        e.isUnsavedChange = true
        Object.assign(e, {
          answer: savedAnswer.answer,
          answerEssay: savedAnswer.answerEssay,
          answerNumber: savedAnswer.answerNumber,
        })
      }
    })

    if (isAssess) {
      q = q.filter((q) => q.typeId === +QuestionTypeEnum.EssayText)
    }
    setQuestions(q)
    setDirty(false)
    if (data.remainingSeconds === undefined || data.remainingSeconds === null) {
      setInfiniteTime(true)
    } else {
      setInfiniteTime(false)
      setCountdown(Date.now() + data.remainingSeconds)
      setCountdownKey(Date.now().toString())
    }
  }, [data])

  useEffect(() => {
    if (showedIndex === undefined && questions.length > 0) {
      if (data.latestAnsweredIndex) {
        setShowedIndex(data.latestAnsweredIndex)
      } else {
        setShowedIndex(0)
      }
    }

    if (data.isExam && isStudent && data.statusId !== +QuizAnswerStatusEnum.Processed) {
      setShowedIndex(-1)
    }
  }, [questions])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const classId = params.get('cid')
    if (classId) {
      setBreadCrumbs((prev) => {
        const newInstance = [...prev]
        const idx = newInstance.findIndex((q) => q.title === 'Class Detail')
        newInstance[idx].path = '/classes/' + classId
        return newInstance
      })
    }
  }, [location.search])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const view = params.get('view')

    if (questions && questions.length > 0) {
      const current = questions[showedIndex || 0]
      if (current) {
        const randomOptions = current.randomOptions ?? []
        current.randomOptions = randomOptions.length > 1 ? randomOptions : [1, 2, 3, 4, 5, 6]
        if (view == '1' && !isStudent) {
          current.randomOptions = [1, 2, 3, 4, 5, 6]
        }
      }
      setShowedQuestion(current)
      setDirty(false)
      if (current && current.id) {
        localStorage.setItem('lastQuestionId', current.id.toString())
      }
    }
  }, [showedIndex])

  useEffect(() => {
    if (!isReadOnly && loaded && showedQuestion?.id) {
      console.log('loaded', isAfterFraud)

      startQuestion(showedQuestion.id, isAfterFraud)
    }
  }, [showedQuestion?.id, loaded, isReadOnly, isAfterFraud])

  const save = (): Promise<any | null> => {
    setIsLoading(true)
    return new Promise<any | null>((resolve, reject) => {
      if (isReadOnly) {
        if (isAssess && dirty) {
          const body: IClassQuizQuestion = {
            ...showedQuestion,
            id: 0,
            questionId: showedQuestion.id,
            answerId: data.id,
          }
          correctionQuiz(body)
            .then(() => {
              setQuestions((prev) => {
                const newInstance = [...prev]
                newInstance[showedIndex || 0] = showedQuestion
                return newInstance
              })
              setIsLoading(false)
              resolve(null)
            })
            .catch((e) => {
              setIsLoading(false)
              reject()
            })
        } else {
          setIsLoading(false)
          resolve(null)
        }
      } else {
        // if (dirty || showedQuestion?.isUnsavedChange) {
        const body: any = {
          id: 0,
          questionId: showedQuestion.id,
          answerId: data.id,
          answer: showedQuestion.answer,
          answerNumber: showedQuestion.answerNumber,
          answerEssay: showedQuestion.answerEssay,
          isSaveAnswer: dirty || showedQuestion?.isUnsavedChange,
          isReadOnly: !dirty && !(showedQuestion?.isUnsavedChange ?? false),
        }

        const funcAnswer = (body: IClassQuizQuestion) => {
          answerQuiz(body)
            .then((resp) => {
              setQuestions((prev) => {
                const newInstance = [...prev]
                newInstance[showedIndex || 0] = {...showedQuestion, isUnsavedChange: false}
                return newInstance
              })

              if (resp?.remainingSeconds !== undefined) {
                const remain = Date.now() + resp.remainingSeconds
                setCountdown(remain)
                setCountdownKey(Date.now().toString())
              }

              removeQuizAnswerQuestion(currentUser?.id, data?.id, showedQuestion?.id)

              setIsLoading(false)
              resolve(resp)
            })
            .catch((e) => {
              // setShowedQuestion((prev) => {
              //   if (showedIndex !== undefined) {
              //     const prevAnswer = questions[showedIndex]
              //     return {...prevAnswer}
              //   }

              //   return prev
              // })

              setIsLoading(false)
              if (e.message === 'Quiz deadline has passed') {
                showError(e.message)
                setIsExamFinished(true)
                getDataByQuizId()
              } else if (e.message.includes('taken by someone else')) {
                setError(e.message)
                setIsExamFinished(true)
              } else if (e.message.includes('already completed')) {
                setError(e.message)
                setIsExamFinished(true)
              } else {
                // showError('Something went wrong while saving your answer. Please try again.')
              }
              reject()
            })
        }

        if (quizHeader?.isStrictQuiz && encryptKey && encryptIv && currentUser) {
          const browserId = getFingerprint(currentUser.id)
          encryptAES(browserId, encryptKey, encryptIv).then((encrypted) => {
            body.browserId = encrypted
            funcAnswer(body)
          })
        } else {
          funcAnswer(body)
        }
        // } else {
        //   setIsLoading(false)
        //   resolve(null)
        // }
      }
    })
  }

  const startQuestion = async (id, _isAfterFraud = false) => {
    console.log(_isAfterFraud)

    try {
      const {
        remainingSeconds,
        questionText,
        id: questionId,
        option1,
        option2,
        option3,
        option4,
        option5,
        option6,
      } = await startQuestionAnswer({
        answerId: data.id,
        questionId: id,
        isAfterFraud: _isAfterFraud,
      })
      if (remainingSeconds !== undefined) {
        setRemainingSecondsPerQuestion({
          timer: remainingSeconds,
          id: id,
        })

        setQuestions((prev) => {
          let newState = [...prev]
          let questionIdx = prev.findIndex((f) => f.id === questionId)
          if (questionIdx >= 0) {
            const newItem = {...newState[questionIdx]}
            newItem.questionText = questionText
            newItem.option1 = option1
            newItem.option2 = option2
            newItem.option3 = option3
            newItem.option4 = option4
            newItem.option5 = option5
            newItem.option6 = option6
            newState[questionIdx] = newItem
          }

          return newState
        })

        if (questionId === showedQuestion?.id) {
          setShowedQuestion((prev) => ({
            ...prev,
            questionText,
            option1,
            option2,
            option3,
            option4,
            option5,
            option6,
          }))
        }
      }
    } catch (e: any) {
      showError(e.message)
    }
  }

  const updateAnswer = (key: string, value: any) => {
    if (!isReadOnly) {
      saveQuizAnswer(currentUser?.id, data?.id, {
        questionId: showedQuestion?.id,
        lastUpdated: new Date().toISOString(),
        [key]: value,
      })
    }
  }

  const changeOption = (number: number) => {
    setShowedQuestion((prev) => {
      const newInstance = {...prev}
      newInstance.answer = number
      return newInstance
    })
    setDirty(true)
    updateAnswer('answer', number)
  }

  const handleChangeNumber = () => {
    const val = numberRef.current?.valueAsNumber || undefined

    setShowedQuestion((prev) => {
      const newInstance = {...prev}
      newInstance.answerNumber = val
      return newInstance
    })
    setDirty(true)
    updateAnswer('answerNumber', val)
  }

  const handleChangeText = () => {
    const textVal = textRef.current?.value

    setShowedQuestion((prev) => {
      const newInstance = {...prev}
      newInstance.answerEssay = textVal
      return newInstance
    })
    setDirty(true)
    updateAnswer('answerEssay', textVal)
  }

  const handleChangeScore = () => {
    setShowedQuestion((prev) => {
      const newInstance = {...prev}
      const score = scoreRef.current?.valueAsNumber || 0
      if (!(score > (newInstance.correctScore || 0)) && Number.isInteger(score)) {
        newInstance.answerScore = score
      }
      return newInstance
    })
  }

  const handleChangeCorrect = () => {
    setShowedQuestion((prev) => {
      const newInstance = {...prev}
      newInstance.isCorrect = correctRef.current?.checked || false
      return newInstance
    })
  }

  const handleSaveDraft = (): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      setLoadingSaveDraft(true)
      const body: any = {
        id: 0,
        questionId: showedQuestion.id,
        answerId: data.id,
        answer: showedQuestion.answer,
        answerNumber: showedQuestion.answerNumber,
        answerEssay: showedQuestion.answerEssay,
        isSaveAnswer: true,
      }

      console.log(body)
      answerQuiz(body)
        .then((resp) => {
          if (resp && resp.endAnswerAt) {
            setShowedQuestion((prev) => ({
              ...prev,
              endAnswerAt: resp.endAnswerAt,
              isUnsavedChange: false,
            }))

            setQuestions((prev) => {
              const newInstance = [...prev]
              newInstance[showedIndex || 0].endAnswerAt = resp.endAnswerAt
              newInstance[showedIndex || 0].isUnsavedChange = false
              return newInstance
            })
          }
          resolve(true)
          setLoadingSaveDraft(false)
        })
        .catch((e) => {
          resolve(false)
          setLoadingSaveDraft(false)
        })
    })
  }

  const changeIndex = (i: number) => {
    if (quizHeader?.isPrevButtonDisabled && !isReadOnly) {
      return
    }
    setIsLoading(true)
    save().then(
      () => {
        setShowedIndex(i)
        setIsLoading(false)
      },
      (error) => {
        // showError(error?.message)
        setIsLoading(false)
      }
    )
  }

  const handlePrevClick = () => {
    setIsLoading(true)
    save().then(
      () => {
        setShowedIndex((prev) => {
          const newIdx = (prev || 0) - 1
          if (newIdx >= (data.isExam && isReadOnly && isStudent ? -1 : 0)) {
            return newIdx
          }

          return prev
        })
      },
      (error) => {
        // showError(error?.message)
        setIsLoading(false)
      }
    )
  }

  const handleNextClick = () => {
    if (isLoading) {
      return
    }

    if (quizHeader?.useTimerPerQuestion) {
      if ((showedIndex ?? 0) + 1 >= questions.length) {
        handleAutoSubmitQuiz()
        return
      }
    }

    setIsLoading(true)
    save().then(
      (resp) => {
        setShowedIndex((prev) => {
          let newIdx = (prev || 0) + 1
          if (resp) {
            const nextIdxFromApi = questions.findIndex((q) => q.id == resp.nextQuestionId)
            if (nextIdxFromApi >= 0) {
              newIdx = nextIdxFromApi
            }
          }
          if (newIdx < questions.length) {
            return newIdx
          }
          return prev
        })
        setIsLoading(false)
        setIsAfterFraud(false)
      },
      (error) => {
        // showError(error?.message)
        setIsLoading(false)
      }
    )
  }

  const handleAutoNextTimer = () => {
    if (quizHeader?.stopTimerOnFraud && isAfterFraud) {
      return
    }
    handleNextClick()
  }

  const handleSubmitQuiz = () => {
    if (!isReadOnly) {
      setIsLoading(true)
      endQuiz(quizId)
        .then(() => {
          getDataByQuizId()
        })
        .catch(() => setIsLoading(false))
        .finally(() => {
          setShowConfirm(false)
          setIsExamFinished(true)
        })
    } else if (isAssess) {
      setIsLoading(true)
      endCorrectionQuiz(quizId)
        .then(() => {
          setIsAssess(false)
          getDataByAnswerId()
        })
        .catch(() => setIsLoading(false))
        .finally(() => setShowConfirmAssess(false))
    }
  }

  const handleAutoSubmitQuiz = () => {
    setAutoSubmitLoading(true)
    let body = {
      quizId: quizId,
      lastAnswer: {
        id: 0,
        questionId: showedQuestion.id,
        answerId: data.id,
        answer: showedQuestion.answer,
        answerNumber: showedQuestion.answerNumber,
        answerEssay: showedQuestion.answerEssay,
        isSaveAnswer: true,
      },
    }
    autoEndQuiz(body)
      .then((resp) => {
        if (resp?.submitted) {
          getDataByQuizId()
        } else if (resp?.remainingSecond !== undefined) {
          setCountdown(Date.now() + resp.remainingSecond)
          setCountdownKey(Date.now().toString())
          showInfo('Extra Time')
        }
      })
      .finally(() => {
        setAutoSubmitLoading(false)
      })
  }

  const getDataByQuizId = () => {
    getQuiz(quizId)
      .then((resp) => {
        if (resp) {
          setShowedIndex(undefined)
          setIsReadOnly(true)
          setData(resp)
          setLoaded(true)
          setIsExamFinished(true)
        }
      })
      .catch((e) => {
        if (e.message === 'Quiz answer not found') {
          setError('You cannot answer this quiz/exam')
        } else if (e.message === 'You do not have permission to access this quiz') {
          setError(e.message)
        }
      })
      .finally(() => {
        setIsLoading(false)
        setIsExamFinished(true)
      })
  }

  const getDataByAnswerId = () => {
    const params = new URLSearchParams(location.search)
    const view = params.get('view')
    getQuizByAnswerId(quizId, view)
      .then((resp) => {
        if (resp) {
          if (resp.statusId === +QuizAnswerStatusEnum.NeedCalculate) {
            setIsAssess(true)
          } else {
            setIsAssess(false)
          }
          setShowedIndex(undefined)
          setIsReadOnly(true)
          setData(resp)
          setLoaded(true)
        }
      })
      .finally(() => setIsLoading(false))
  }

  const handleCloseConfirm = () => {
    setShowConfirm(false)

    if (quizHeader?.useTimerPerQuestion) {
      return
    }

    const index = questions.findIndex(
      (q) =>
        !q.answer && (q.answerNumber === null || q.answerNumber === undefined) && !q.answerEssay
    )

    if (index !== -1) {
      setShowedIndex(index)
    }
  }

  const handleShowConfirm = () => {
    save().then(() => {
      setShowConfirm(true)
    })
  }

  const handleCloseConfirmAssess = () => {
    setShowConfirmAssess(false)
  }

  const handleShowConfirmAssess = () => {
    save().then(() => {
      setShowConfirmAssess(true)
    })
  }

  const getAnsweredQuestion = (): number => {
    const multiple = questions.filter(
      (q) => q.typeId === QuestionTypeEnum.MultipleChoice && q.answer
    )
    const number = questions.filter(
      (q) => q.typeId === QuestionTypeEnum.EssayNumber && q.answerNumber !== undefined
    )
    const essay = questions.filter((q) => q.typeId === QuestionTypeEnum.EssayText && q.answerEssay)

    return multiple.length + number.length + essay.length
  }

  const handleCompleteCountdown = () => {
    if (quizHeader.autoSubmit) {
      handleAutoSubmitQuiz()
    }
  }

  useEffect(() => {
    if (!isReadOnly && isStudent) {
      const syncRemainingFunc = async () => {
        const result = await getRemaining(data.id)
        if (result.data != undefined) {
          const {remainingSeconds, isPrevButtonDisabled, useTimerPerQuestion} = result.data
          const remain = Date.now() + remainingSeconds
          setCountdown(remain)
          setCountdownKey(Date.now().toString())
          setQuizHeader((prev) => ({...prev, isPrevButtonDisabled, useTimerPerQuestion}))
        }
      }

      if (!syncRemainingTimerId) {
        const id = setInterval(syncRemainingFunc, 30 * 1000)
        setSyncRemainingTimerId(id)
      }

      return () => {
        if (syncRemainingTimerId) {
          clearInterval(syncRemainingTimerId)
        }
      }
    }
  }, [syncRemainingTimerId, isReadOnly, isStudent])

  const errorFromWrapperHandler = (message: string) => {
    setIsExamFinished(true)
    setError(message)
  }

  const examStatChangeHandler = (isStarted: boolean) => {
    if (isStarted) {
      console.log('examStatChangeHandler')

      startQuestion(showedQuestion.id)
    }
  }

  return (
    <div style={{position: 'relative'}}>
      <PageTitle breadcrumbs={breadCrumbs} showBackButton={true}>
        Quiz
      </PageTitle>
      <QuizWrapper
        quizId={quizId}
        onStart={startHandler}
        onStartWithPicture={startHandlerWithPicture}
        nim={quizHeader?.nim}
        statusId={quizHeader?.statusId}
        isStudent={isStudent}
        isExamFinished={isExamFinished}
        isStrictQuiz={quizHeader?.isStrictQuiz}
        isNew={quizHeader?.isNew}
        allowedBrowsers={quizHeader?.allowedBrowsers}
        isExam={quizHeader?.isExam}
        showedQuestion={showedQuestion}
        answerId={data.id}
        canUnlock={quizHeader?.canUnlock || false}
        isUseCamera={quizHeader?.useCamera || false}
        onErrorFromWrapper={errorFromWrapperHandler}
        allowedOs={quizHeader?.allowedOs}
        onExamStatChange={examStatChangeHandler}
        lastFraudStartDate={quizHeader?.lastFraudStartDate}
        totalFraud={quizHeader?.totalFraud}
        header={
          <>
            <QuizHeader
              quizHeader={quizHeader}
              isReadOnly={isReadOnly}
              data={data}
              countdown={countdown}
              handleCompleteCountdown={handleCompleteCountdown}
              countdownKey={countdownKey}
              infiniteTime={infiniteTime}
              isStudent={isStudent}
            />
          </>
        }
      >
        {error && (
          <div
            className='d-flex align-items-center justify-content-center fs-2 fw-bold text-muted'
            style={{minHeight: '300px'}}
          >
            {error}
          </div>
        )}
        {!error && (
          <div className='col-12'>
            {(data.statusId === +QuizAnswerStatusEnum.Completed ||
              data.statusId === +QuizAnswerStatusEnum.NeedCalculate ||
              !isReadOnly ||
              !isStudent) && (
              <div className='d-flex flex-xl-row flex-column'>
                <div className='flex-row-fluid'>
                  <div className='card' style={{minHeight: '300px'}}>
                    {showedIndex === -1 && (
                      <>
                        <QuizSummaryResult
                          data={data}
                          handleNextClick={handleNextClick}
                          handlePrevClick={handlePrevClick}
                          isLoading={isLoading}
                        />
                      </>
                    )}
                    {showedQuestion && showedIndex !== -1 && (
                      <div className='card-body pt-9 pb-9 d-flex flex-column justify-content-between'>
                        <div className='d-flex flex-column'>
                          <QuizQuestion
                            data={data}
                            isReadOnly={isReadOnly}
                            isStudent={isStudent}
                            showedIndex={showedIndex}
                            showedQuestion={showedQuestion}
                            infiniteTime={infiniteTime}
                            handleNextClick={handleAutoNextTimer}
                            remainingSecond={remainingSecondsPerQuestion}
                            useTimerPerQuestion={quizHeader?.useTimerPerQuestion}
                          />

                          {showedQuestion?.isUnsavedChange && (
                            <div className='text-end' style={{marginTop: 0}}>
                              <span className='badge badge-danger'>Unsaved Changes</span>
                            </div>
                          )}

                          {showedQuestion.typeId === +QuestionTypeEnum.MultipleChoice && (
                            <>
                              {showedQuestion.randomOptions?.map((q, i) => {
                                return (
                                  <QuizMultipleChoice
                                    key={i}
                                    changeOption={changeOption}
                                    data={data}
                                    isLoading={isLoading}
                                    isReadOnly={isReadOnly}
                                    isStudent={isStudent}
                                    q={q}
                                    showedQuestion={showedQuestion}
                                  />
                                )
                              })}
                            </>
                          )}

                          {showedQuestion.typeId === +QuestionTypeEnum.EssayNumber && (
                            <QuizEssayNumber
                              isReadOnly={isReadOnly}
                              isLoading={isLoading}
                              showedQuestion={showedQuestion}
                              handleChangeNumber={handleChangeNumber}
                              numberRef={numberRef}
                            />
                          )}

                          {showedQuestion.typeId === +QuestionTypeEnum.EssayText && (
                            <>
                              <QuizEssayText
                                correctRef={correctRef}
                                handleChangeCorrect={handleChangeCorrect}
                                handleChangeScore={handleChangeScore}
                                handleChangeText={handleChangeText}
                                isAssess={isAssess}
                                isLoading={isLoading}
                                isReadOnly={isReadOnly}
                                scoreRef={scoreRef}
                                showedQuestion={showedQuestion}
                                textRef={textRef}
                                handleSaveDraft={handleSaveDraft}
                                isLoadingDraft={loadingSaveDraft}
                              />
                            </>
                          )}
                        </div>

                        <QuizNavigationButton
                          data={data}
                          handleNextClick={handleNextClick}
                          handlePrevClick={handlePrevClick}
                          isLoading={isLoading}
                          isReadOnly={isReadOnly}
                          isStudent={isStudent}
                          questions={questions}
                          showedIndex={showedIndex}
                          isPrevButtonDisabled={quizHeader?.isPrevButtonDisabled}
                          showedQuestion={showedQuestion}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className='w-xl-400px w-100 ms-xl-5 mt-xl-0 ms-0 mt-5 flex-shrink-0'>
                  <QuizNavigationGrid
                    changeIndex={changeIndex}
                    data={data}
                    handleShowConfirm={handleShowConfirm}
                    handleShowConfirmAssess={handleShowConfirmAssess}
                    isAssess={isAssess}
                    isLoading={isLoading}
                    isReadOnly={isReadOnly}
                    isStudent={isStudent}
                    questions={questions}
                    showedIndex={showedIndex}
                    isPrevButtonDisabled={quizHeader?.isPrevButtonDisabled}
                  />
                </div>
              </div>
            )}
            {/* {(data.statusId === +QuizAnswerStatusEnum.NeedCalculate || !isReadOnly || !isStudent) && (
              <div className='d-flex flex-xl-row flex-column'>
                <div className='flex-row-fluid'>
                  <div className='card' style={{minHeight: '300px'}}>
                    {showedIndex === -1 && (
                      <>
                        <QuizSummaryResult
                          data={data}
                          handleNextClick={handleNextClick}
                          handlePrevClick={handlePrevClick}
                          isLoading={isLoading}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            )} */}
          </div>
        )}
        {isLoading && <BlockLoading fullBlocking={true} />}
        {autoSubmitLoading && (
          <BlockLoading fullBlocking={true} text='Your time is up, submiting...' />
        )}
        <ConfirmEndQuiz
          handleConfirm={handleSubmitQuiz}
          handleClose={handleCloseConfirm}
          show={showConfirm}
          answeredCount={getAnsweredQuestion()}
          questionCount={questions.length}
          isLoading={isLoading}
        />
        <ConfirmEndAssess
          handleConfirm={handleSubmitQuiz}
          handleClose={handleCloseConfirmAssess}
          show={showConfirmAssess}
          isLoading={isLoading}
        />
      </QuizWrapper>
    </div>
  )
}

export {Quiz}
