import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, MenuModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'
import {AxiosError} from 'axios'
import {Error500} from '../../errors/components/Error500'
import {OtpRequest} from './AuthHelpers'
import {parseJsonAndPascalToCamel} from '../../../utils/array'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
  currentMenu: MenuModel[] | undefined
  setCurrentMenu: Dispatch<SetStateAction<MenuModel[] | undefined>>
  isOtpValid: boolean
  setOtp: (otp: string) => void
  getOtp: () => authHelper.OtpLocalStorage | null
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
  currentMenu: undefined,
  setCurrentMenu: () => {},
  isOtpValid: authHelper.isOtpValid(),
  setOtp: () => authHelper.setOtp(''),
  getOtp: () => authHelper.getOtp(),
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [currentMenu, setCurrentMenu] = useState<MenuModel[] | undefined>()
  const [isOtpValid] = useState<boolean>(authHelper.isOtpValid())

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const setOtp = authHelper.setOtp

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
    setCurrentMenu(undefined)
    localStorage.clear()
  }

  const getOtp = authHelper.getOtp

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        logout,
        currentMenu,
        setCurrentMenu,
        isOtpValid,
        setOtp,
        getOtp,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser, setCurrentMenu} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const [showServerDown, setShowServerDown] = useState(false)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          setShowSplashScreen(true)
          const {data} = await getUserByToken(apiToken)

          if (data) {
            const {token, userInfo} = data.data

            console.log(userInfo)

            setCurrentUser({
              username: userInfo.loginName,
              id: userInfo.id,
              name: userInfo.name,
              roleId: userInfo.roles[0].id,
              roleName: userInfo.roles[0].name,
              email: userInfo.additionalInfo.email,
              majorName: userInfo.additionalInfo.studentMajorName,
              degreeName: userInfo.additionalInfo.studentMajorDegreeName,
              nim: userInfo.additionalInfo.studentMajorNim,
              limitFileSize: userInfo.additionalInfo.limitFileSize,
              limitFileSizeMb: userInfo.additionalInfo.limitFileSize / 1048576,
              staffMajors: userInfo.additionalInfo.staffMajorList
                ? parseJsonAndPascalToCamel(userInfo.additionalInfo.staffMajorList)
                : null,
            })
          }
        }
      } catch (error) {
        console.error(error)
        if (error instanceof AxiosError) {
          if (error.code === AxiosError.ERR_NETWORK) {
            setShowServerDown(true)
          }
        }
        // if (!didRequest.current) {
        //   logout()
        // }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    //TODO VERIF
    if (auth && auth.api_token) {
      requestUser(auth.api_token)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  const handleReload = () => {
    window.location.reload()
  }

  return showSplashScreen ? (
    <LayoutSplashScreen />
  ) : showServerDown ? (
    <>
      <div className='d-flex flex-column flex-root'>
        <div className='d-flex flex-column flex-center flex-column-fluid'>
          <div className='d-flex flex-column flex-center text-center p-10'>
            <div className='card card-flush  w-lg-650px py-5'>
              <div className='card-body py-15 py-lg-20'>
                <Error500 refresh={handleReload} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>{children}</>
  )
}

export {AuthProvider, AuthInit, useAuth}
