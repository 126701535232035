import {useState} from 'react'
import {MenuModel, useAuth} from '../../../../../app/modules/auth'
import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'

const SidebarMenu = () => {
  const {currentMenu} = useAuth()
  const [searchQuery, setSearchQuery] = useState('')

  const filteredMenu = currentMenu?.filter((parent) => {
    const matchesParent = parent.title.toLowerCase().includes(searchQuery.toLowerCase())

    const filteredChildren = parent.children?.filter((child) =>
      child.title.toLowerCase().includes(searchQuery.toLowerCase())
    )

    return matchesParent || (filteredChildren && filteredChildren.length > 0)
  })

  return (
    <div className='app-sidebar-menu overflow-hidden flex-column-fluid'>
      <div
        id='kt_app_sidebar_menu_wrapper'
        className='app-sidebar-wrapper hover-scroll-overlay-y my-5'
      >
        <div
          className='menu menu-column menu-rounded menu-sub-indention px-3'
          id='#kt_app_sidebar_menu'
          data-kt-menu='true'
          data-kt-menu-expand='false'
        >
          <input
            type='text'
            className='form-control form-control-sm ml-3 mr-3 mb-3'
            placeholder='Search menu...'
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />

          {filteredMenu?.map((parent, index) => {
            const filteredChildren = parent.children?.filter((child) =>
              child.title.toLowerCase().includes(searchQuery.toLowerCase())
            )

            if (!parent.children || filteredChildren?.length === 0) {
              return (
                <SidebarMenuItem
                  key={index}
                  index={index}
                  title={parent.title}
                  to={parent.route}
                  icon={parent.icon}
                  isExt={parent.route.includes('http')}
                />
              )
            }

            return (
              <SidebarMenuItemWithSub
                to={parent.route}
                key={index}
                title={parent.title}
                hasBullet={false}
                isOpen={index === 0 && filteredChildren?.length !== 0}
              >
                {filteredChildren?.map((menu, idx) => (
                  <SidebarMenuItem
                    key={idx}
                    index={idx}
                    title={menu.title}
                    to={menu.route}
                    icon={menu.icon}
                    isExt={menu.route.includes('http')}
                  />
                ))}
              </SidebarMenuItemWithSub>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export {SidebarMenu}
