import {Route, Routes} from 'react-router-dom'
import {FC} from 'react'
import ForgotPasswordEmailForm from './components/ForgotPasswordEmailForm'
import ForgotPasswordReset from './components/ForgotPasswordReset'
import {AuthLayout} from '../auth/AuthLayout'

const ForgotPasswordPage: FC = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='reset' element={<ForgotPasswordReset />} />
      <Route index element={<ForgotPasswordEmailForm />} />
    </Route>
  </Routes>
)

export {ForgotPasswordPage}
