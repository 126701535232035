/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {Outlet, Link, useParams} from 'react-router-dom'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {getMeetByToken} from '../core/_request'
import {MeetAttendView} from '../core/_model'
import {formatDate, formatDateTime, formatTime} from '../../../utils/date'
import {AttendanceStudentForm} from './AttendanceStudentForm'
import AttendanceStudentError from './AttendanceStudentError'
import BlockLoading from '../../../components/BlockLoading'

const AttendanceStudent = () => {
  const params = useParams()
  const [meetInfo, setMeetInfo] = useState<MeetAttendView | undefined>(undefined)
  const [error, setError] = useState<string | null>(null)
  const [token, setToken] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const _token = params.token
    if (!_token) {
      return
    }

    setToken(_token)
  }, [params])

  useEffect(() => {
    if (token) {
      setIsLoading(true)
      getMeetByToken(token ?? '')
        .then((result) => {
          if (result.code === '0000') {
            setMeetInfo(result.data)
          } else {
            setError(result.message ?? '')
          }
          setIsLoading(false)
        })
        .catch((e) => {
          setError(e.message)
          setIsLoading(false)
        })
    }
  }, [token])

  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-column-fluid py-10 px-16 position-relative'>
      {isLoading && (
        <div style={{height: 120}}>
          <BlockLoading />
        </div>
      )}
      {meetInfo && (
        <>
          <div>
            <div className='text-center mb-4'>
              <h1 className='text-dark fw-bolder mb-2'>{meetInfo?.classInfo?.courseName}</h1>
              <div className='text-gray-500 fw-semibold fs-5 mb-1'>
                <span className='pe-4'>
                  {meetInfo?.classInfo?.degreeName} - {meetInfo?.classInfo?.majorName}
                </span>
              </div>
              <div className='text-gray-500 fw-semibold fs-5'>
                <span className='me-4 d-inline-flex flex-center'>
                  <KTIcon iconName='time' iconType='solid' className='me-1 fs-4' />
                  {formatDateTime(meetInfo?.startDate)}
                </span>
                <span className='d-inline-flex flex-center'>
                  <KTIcon iconName='home' iconType='solid' className='me-1 fs-4' />
                  {meetInfo?.roomName}
                </span>
              </div>
            </div>
          </div>

          <AttendanceStudentForm token={token} />
        </>
      )}
      {error && <AttendanceStudentError message={error} />}
    </div>
  )
}

export default AttendanceStudent
